import React from "react";

/** COMPONENTS */
import OnboardingCredits from "../components/OnboardingFundingCredits.component";
import OnboardingIncomes from "../components/OnboardingFundingIncomes.component";
import OnboardingContribution from "../components/OnboardingFundingContribution.component";
import OnboardingProperty from "../components/OnboardingFundingProperty.component";
import OnboardingPropertyDetails from "../components/OnboardingFundingPropertyDetails.component";
import OnboardingBirthday from "../components/OnboardingFundingBirthday.component";

export const COnboardingFundingNavigator: Array<React.ReactElement> = [
	<OnboardingIncomes />,
	<OnboardingCredits />,
	<OnboardingContribution />,
	<OnboardingProperty />,
	<OnboardingPropertyDetails/>,
	<OnboardingBirthday />,
];
