import React from "react";
import "./scss/OnboardingInputBirthday.scss";

/** ASSETS */
import { ReactComponent as Close } from "assets/svg/card-input-close-icon.svg";

interface IProps {
	label: string;
	inputName: string;
	icon: React.ReactElement;
	units: Array<string>;
	value: string;
	isClicked?: boolean;
	CBHandleChange: (event: any) => void;
	CBHandleClose?: () => void;
}

const OnboardingInputBirthday: React.FC<IProps> = ({
	label,
	inputName,
	icon,
	units,
	value,
	isClicked,
	CBHandleChange,
	CBHandleClose,
}) => {
	return (
		<div className="column onboarding-input-card-birthday">
			<div className="flex space-between header">
				<div
					className="label"
					style={{
						background: label === "" ? "transparent" : "var(--light-grey)",
					}}
				>
					<span>{label}</span>
				</div>
				{CBHandleClose !== undefined && (
					<button onClick={CBHandleClose}>
						<Close />
					</button>
				)}
			</div>

			<div className="icon">{icon}</div>

			<div className={isClicked ? 'flex items-center content-center isClickedError' : 'flex items-center content-center value' }>
				<input
					name={inputName}
					value={value}
					onChange={CBHandleChange}
					type="tel"
					required
					// style={{width: (value.length + 1) * 13}}
				/>

				{units.length > 0 && (
					<div className="units">
						<span>{units[0]}</span>

						{units.length === 2 && <span>{units[1]}</span>}
					</div>
				)}
			</div>
		</div>
	);
};

export default OnboardingInputBirthday;
