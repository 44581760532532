import React from "react";
import "./scss/DownloadFundingAppModal.scss";

/** ASSETS */
// import { ReactComponent as AppStoreIcon } from "assets/svg/appstore-download.svg";
// import { ReactComponent as PlayStoreIcon } from "assets/svg/playstore-download.svg";
// import { ReactComponent as FridaaLogoIcon } from "assets/svg/fridaa-homepage-logo.svg";
// import { ReactComponent as CloseIcon } from "assets/svg/x.svg";

/** COMPONENTS */
// import DownloadAppCarousel from "./components/DownloadFundingAppCarousel.component";
import DownloadAppModalDesktop from "./components/DownloadFundingAppModalDesktop.component";

/** MATERIAL UI */
import { Dialog, DialogContent } from "@material-ui/core";

/** UTILS */
// import { detectDeviceSysteme } from "utils/detectDeviceSysteme.util";
// import { openInNewTab } from "utils/openInNewTab.util";
// import { isDesktopWidth } from "utils/isDesktopWidth.util";

interface IProps {
	CBHandleClose: () => void;
	dataFetched: any;
}

const DownloadAppModal: React.FC<IProps> = ({ CBHandleClose, dataFetched }) => {
	// const [isDesktop, setIsDesktop] = useState<boolean>(false);
	// const [deviceSysteme, setDeviceSysteme] = useState<string | null>(null);

	// useEffect(() => {
		// setDeviceSysteme(detectDeviceSysteme);
		// setIsDesktop(isDesktopWidth);
	// }, []);

		return (
			<Dialog
				className="download-app-modal"
				open={true}
				keepMounted
				onClose={CBHandleClose}
			>
				<DialogContent className="column">
					<DownloadAppModalDesktop CBHandleClose={CBHandleClose} dataFetched={dataFetched} />
				</DialogContent>
			</Dialog>
		);
};

export default DownloadAppModal;
