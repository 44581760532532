import React, { useState } from "react";
import "./scss/PassportModal.scss";

/** ASSETS */
import { ReactComponent as IlluPassport } from "assets/svg/illustration-passport.svg";
import { ReactComponent as CloseIcon } from "assets/svg/x.svg";

/** COMPONENTS */
import DownloadAppModal from "components/common/DownloadAppModal/DownloadAppModal.component";
import DiscoverFundingPasseportButton from "components/common/DiscoverFundingPasseportButton/DiscoverFundingPasseportButton.component";
import CapacityFundingPassportInput from "components/common/CapacityFundingPassportInput/CapacityFundingPassportInput";
// import OnboardingFundingPassportInputCard from "components/common/OnboardingFundingPassportInputCard/OnboardingFundingPassportInputCard.component";

/** ENUMS */
// import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
// import { logEvent } from "firebase/analytics";
// import analytics from "firebase-config";

/** MATERIAL UI */
import { Dialog, DialogContent } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

/** SERVICES */
import apiService from "services/api.service";

/** STORE */
import { useAppSelector } from "hooks";
import { selectStoreCapacity } from "features/capacity/capacityFundingSlice";

interface IProps {
  CBHandleClose: () => void;
  dataFetched?: any;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props: any, ref: any) {
    const isDesktop: boolean = window.innerWidth > 769;

    return <Slide direction={isDesktop ? "left" : "up"} ref={ref} {...props} />;
  }
);

const PassportModal: React.FC<IProps> = ({ CBHandleClose, dataFetched }) => {
  const capacity: any = useAppSelector(selectStoreCapacity);
  const [displayDownloadModal, setDisplayDownloadModal] =
    useState<boolean>(false);
  const [familyName, setFamilyName] = useState<string>(localStorage.getItem(`${process.env.REACT_APP_PARTNER_PREFIX}firstname-borrower`) || "");
  const [firstName, setFirstName] = useState<string>(localStorage.getItem(`${process.env.REACT_APP_PARTNER_PREFIX}lastname-borrower`) || "");
  const [COfamilyName, setCOfamilyName] = useState<string>(localStorage.getItem(`${process.env.REACT_APP_PARTNER_PREFIX}firstname-coborrower`) || "");
  const [COfirstName, setCOfirstName] = useState<string>(localStorage.getItem(`${process.env.REACT_APP_PARTNER_PREFIX}lastname-coborrower`) || "");
  // const [passportFetched, setPassportFetched] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [displayErrorMessage, setDisplayErrorMessage] =
    useState<boolean>(false);
    // const [dummySetter, setDummySetter] =
    // useState<boolean>(false);
    const REACT_APP_FRIDAA_API_BASE_URI: string | undefined =
      process.env.REACT_APP_FRIDAA_API_BASE_URI;

  const handleChangeFamilyName = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    // console.log('name', event.target.name);

    setFamilyName(event.target.value);
  };

  const handleChangeFirstName = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    // console.log('name', event.target.name);
    setFirstName(event.target.value);
  };

  const handleChangeCOFamilyName = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    // console.log('name', event.target.name);
    setCOfamilyName(event.target.value);
  };

  const handleChangeCOFirstName = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    // console.log('name', event.target.name);
    setCOfirstName(event.target.value);
  };

  const handlePasssportClick = () => {
    if (familyName !== "" && firstName !== "") {
      const fetchDataPasseport = async () => {
        try {
          setLoading(true);
          setDisplayErrorMessage(false);
          return await apiService
            .post(
              `${REACT_APP_FRIDAA_API_BASE_URI}/webappfundings/generatefundingpassport`,
              {
                firstname: firstName,
                lastname: familyName,
                firstnameCoborrower: COfirstName,
                lastnameCoborrower: COfamilyName,
                fundingId: dataFetched.fundingId,
              }
            )
            .then((res: any) => {
              // setPassportFetched(res.data);
              setLoading(false);
              CBHandleClose();
              // console.log('URL', res.data.url)
              // window.open(res.data.url, "_blank");
              if (!window.open(res.data.url)) {
                window.location.href = res.data.url
              }
            });
        } catch (error) {
          setLoading(false);
          console.log(error);
          return [];
        }
      };
      fetchDataPasseport();
    } 
    if(familyName === "" || firstName === "") {
      setDisplayErrorMessage(true);
    }
  };
  return (
    <>
      <Dialog
        className="passport-modal"
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={CBHandleClose}
      >
        <DialogContent>
          <button className="close" onClick={CBHandleClose}>
						<CloseIcon />
					</button>
          <div className="flex space-between space-around top">
            <h1>
              Nous c'est Fridaa. Et vous ?
            </h1>
            <IlluPassport />
          </div>
          <div className="column modal-dialog-content">
            <CapacityFundingPassportInput
              label={"Nom"}
              inputName="Nom"
              value={familyName}
              CBHandleChange={handleChangeFamilyName}
              error={displayErrorMessage}
              // setError={setDisplayErrorMessage}

            />
          </div>
          <div className="column modal-dialog-content">
            <CapacityFundingPassportInput
              label={"Prénom"}
              inputName="Prénom"
              value={firstName}
              CBHandleChange={handleChangeFirstName}
              error={displayErrorMessage}
              // setError={setDisplayErrorMessage}
            />
          </div>
          {
            capacity.coBorrowerIncomes && capacity.coBorrowerIncomes > 0
            ?
            <>
            <div className="column modal-dialog-content">
              <CapacityFundingPassportInput
                label={"Nom du co-emprunteur"}
                inputName="CONom"
                value={COfamilyName}
                CBHandleChange={handleChangeCOFamilyName}
                // setError={setDummySetter}
              />
            </div>
            <div className="column modal-dialog-content">
              <CapacityFundingPassportInput
                label={"Prénom du co-emprunteur"}
                inputName="COPrénom"
                value={COfirstName}
                CBHandleChange={handleChangeCOFirstName}
                // setError={setDummySetter}
              />
            </div>
            </>
            :
            null
          }

          {displayErrorMessage && (
            <h6 className="errorMessage">
              Veuillez renseigner le Nom et le Prénom de l'emprunteur.
            </h6>
          )}

          <DiscoverFundingPasseportButton
            callback={handlePasssportClick}
            title={loading ? "TÉLÉCHARGEMENT EN COURS ..." : "TÉLÉCHARGER MON PASSEPORT"}
            disabled={loading}
          />

        </DialogContent>
      </Dialog>

      {displayDownloadModal && (
        <DownloadAppModal
          CBHandleClose={() => setDisplayDownloadModal(false)}
        />
      )}
    </>
  );
};

export default PassportModal;
