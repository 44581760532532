import React from "react";
import "./scss/ModalInputValueCard.scss";

/** COMPONENTS */
// import InputValueBlock from "../InputValueBlock/InputValueBlock.component";

/** MATERIAL UI */
import { Dialog, DialogContent } from "@material-ui/core";

interface IProps {
	children: any;
	CBHandleClose: () => void;
}

const ModalInputValueCard: React.FC<IProps> = ({ children, CBHandleClose }) => {
	return (
		<Dialog
			className="modal-input-value-card"
			open={true}
			keepMounted
			onClose={CBHandleClose}
			>
			<DialogContent className="column">{children}</DialogContent>
		</Dialog>
	);
};

export default ModalInputValueCard;
