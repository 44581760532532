import React from "react";
import "./App.scss";

/** COMPONENTS */
import Layout from "components/Layout/Layout.component";

/** REACT ROUTER DOM */
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

/** SCREENS */
import HomepageScreen from "./screens/Homepage/Homepage.screen";
import CapacityFundingScreen from "screens/CapacityFunding/CapacityFunding.screen";
import OnboardingFundingScreen from "screens/OnboardingFunding/OnboardingFunding.screen";

import CapacityScreen from "screens/Capacity/Capacity.screen";
import OnboardingScreen from "screens/Onboarding/Onboarding.screen";

export const history = createBrowserHistory({ forceRefresh: true });

const App = () => {
	return (
		<Router history={history}>
			<Switch>
				{/* <div id="mobile-layout"> */}
				<Route exact path="/" component={HomepageScreen} />
				{/* <Route exact path={"/"} render={() => <Redirect to="/onboardingFunding" />} /> */}

				<Layout>
					<Switch>

						<Route exact path="/capacity" component={CapacityScreen} />

						<Route exact path="/onboarding" component={OnboardingScreen} />

						<Route exact path="/capacityFunding" component={CapacityFundingScreen} />

						<Route exact path="/onboardingFunding" component={OnboardingFundingScreen} />
					</Switch>
				</Layout>

				{/* </div> */}
			</Switch>
		</Router>
	);
}

export default App;
