import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

/** INTERFACES */

const initialState: any = {
  monthlyIncomes: null,
  mainBorrowerIncomes: null,
  coBorrowerIncomes: null,
  monthlyCredits: null,
  personalContribution: null,
  // type: 0,
  constructPrice: null,
  landPrice: null,
  price: null,
  worksPrice: null,
  age: null,
  ageCoborrower: null,
  duration: 25,
  creditCost: null,
  monthlyPayment: null,
  contribution: null,
  result: null,
  resultWithInsurance: null,
  newGlobalFees: null,
  borrowingCapitalWithInsurance: null,
  debtCapacity: null,
  estateType: 0,
  rate: 0
};

export const capacitySlice = createSlice({
  name: "capacityFunding",
  initialState,
  reducers: {
    updateFundingStoreCapacity: (state: any, action: PayloadAction<any | any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateFundingStoreCapacity } = capacitySlice.actions;

export const selectStoreCapacity = (state: RootState) => state.capacityFunding;

export const selectStoreCapacityMonthlyAmount = (state: RootState) =>
  state.capacityFunding.monthlyAmount;
export const selectStoreCapacityDebtCapacity = (state: RootState) =>
  state.capacityFunding.debtCapacity;
export const selectStoreCapacityEstateType = (state: RootState) =>
  state.capacityFunding.estateType;
export const selectStoreCapacityConstructPrice = (
  state: RootState
): number | null => state.capacityFunding.constructPrice;
export const selectStoreCapacityLandPrice = (state: RootState): number | null =>
  state.capacityFunding.landPrice;
export const selectStoreCapacityPrice = (state: RootState): number | null =>
  state.capacityFunding.price;
export const selectStoreCapacityWorksPrice = (
  state: RootState
): number | null => state.capacityFunding.worksPrice;
export const selectStoreCapacityPersonalContribution = (
  state: RootState
): number | null => state.capacityFunding.personalContribution;
export const selectStoreCapacityRate = (
  state: RootState
): number | null => state.capacityFunding.rate;

export default capacitySlice.reducer;
