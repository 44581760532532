import React from "react";
import "./scss/FinancialInfos.scss";

/** COMPONENTS */
import InputValueBlock from "../InputValueBlock/InputValueBlock.component";

interface IProps {
	monthlyIncomes: number | null;
	mainBorrowerIncomes: number;
	coBorrowerIncomes: number;
	monthlyCredits: number;
	personalContribution: number;
	CBHandleChange: (event: any) => void;
	CBHandleSubmit: (eventName: string) => void;
}

const FinancialInfos: React.FC<IProps> = ({
	monthlyIncomes,
	mainBorrowerIncomes,
	coBorrowerIncomes,
	monthlyCredits,
	personalContribution,
	CBHandleChange,
	CBHandleSubmit,
}) => {
	return (
		<div className="financial-infos">
			<h2>Mes infos financières</h2>

			<div className="column">
				<InputValueBlock
					iconName="picto-revenus"
					name="monthlyIncomes"
					modalNames={["mainBorrowerIncomes", "coBorrowerIncomes"]}
					label="revenus"
					value={monthlyIncomes}
					modalValues={[mainBorrowerIncomes, coBorrowerIncomes]}
					CBHandleChange={CBHandleChange}
					CBHandleSubmit={CBHandleSubmit}
				/>
				<InputValueBlock
					iconName="picto-credit"
					name="monthlyCredits"
					modalNames={["monthlyCredits"]}
					label="crédit"
					value={monthlyCredits}
					modalValues={[monthlyCredits]}
					CBHandleChange={CBHandleChange}
					CBHandleSubmit={CBHandleSubmit}
				/>
				<InputValueBlock
					iconName="picto-apport"
					name="personalContribution"
					modalNames={["personalContribution"]}
					label="apport"
					value={personalContribution}
					modalValues={[personalContribution]}
					CBHandleChange={CBHandleChange}
					CBHandleSubmit={CBHandleSubmit}
				/>
			</div>
		</div>
	);
};

export default FinancialInfos;
