import React from "react";
import "./scss/Layout.scss";

/** COMPONENTS */
import Sidebar from "components/Sidebar/Sidebar.component";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface IProps {
  children: any;
}

const Layout: React.FC<IProps> = ({children}) => {
	return (
		<div id="layout" className="flex">
			<section className="desktop-sidebar">
				<Sidebar />
      		</section>
			<main>
				<ToastContainer />
				{children}
			</main>
		</div>
	);
};

export default Layout;
