import { IBankRates } from "interfaces/IBankRates.interface";
import bankRatesService from "./bankRates.service";

class capacityService {
  private calculateDebtCapacity = (incomes: number, credit: number): number => {
    return Math.max(0, incomes * 0.33 - credit);
  };

  private getFeeByType = (amount: number, type: 0 | 1 | 2 | 3 = 0) => {
    const fees: any = {
      0: 7.7 / 100,
      1: 7.7 / 100,
      2: 7.7 / 100,
      3: 3.3 / 100,
    };
    const fee: number = fees[type];

    switch (type) {
      case 1:
        return amount * 0.75 * fee;
      case 2:
        return amount * 0.5 * fee;
      default:
        return amount * fee;
    }
  };

  private getBorrowingCapital = (
    monthlyPayment: number = 0,
    monthsNumber: number = 300,
    t: number = 0
  ): number => {
    const tx: number = t / 12;
    const txn: number = Math.pow(1 + tx, monthsNumber);

    // console.log(Math.max(0, monthlyPayment) * ((txn - 1) / (tx * txn)));

    return Math.max(0, monthlyPayment) * ((txn - 1) / (tx * txn));
  };

  private getBuyingCapacity = (
    capital: number,
    contribution: number = 0,
    type: 0 | 1 | 2 | 3 = 0
  ): number => {
    const total: number = capital + contribution;

    switch (type) {
      case 1:
        return total - this.getFeeByType(total, type);

      case 2:
        return total - this.getFeeByType(total, type);

      default:
        return total - this.getFeeByType(total, type);
    }
  };

  public instantiateCapacity = () => {
    let localStorage: any = window.localStorage.getItem("onboardingFunding");

    if (localStorage === null || JSON.parse(localStorage) == null) {
      return {
        monthlyIncomes: 0,
        mainBorrowerIncomes: 0,
        coBorrowerIncomes: 0,
        monthlyCredits: 0,
        personalContribution: 0,
      };
    } else {
      localStorage = JSON.parse(localStorage);
    }

    const mainBorrowerIncomes: number = isNaN(parseInt(localStorage.incomes))
      ? 0
      : parseInt(localStorage.incomes);
    const coBorrowerIncomes: number = isNaN(
      parseInt(localStorage.borrowerIncomes)
    )
      ? 0
      : parseInt(localStorage.borrowerIncomes);
    const monthlyIncomes: number = mainBorrowerIncomes + coBorrowerIncomes;
    const monthlyCredits: number = isNaN(parseInt(localStorage.monthlyCredits))
      ? 0
      : parseInt(localStorage.monthlyCredits);
    const personalContribution: number = isNaN(
      parseInt(localStorage.personalContribution)
    )
      ? 0
      : parseInt(localStorage.personalContribution);

    const constructPrice: number = isNaN(parseInt(localStorage.constructPrice))
      ? 0
      : parseInt(localStorage.constructPrice);

    const landPrice: number = isNaN(parseInt(localStorage.landPrice))
      ? 0
      : parseInt(localStorage.landPrice);

    const price: number = isNaN(parseInt(localStorage.price))
      ? 0
      : parseInt(localStorage.price);

    const worksPrice: number = isNaN(parseInt(localStorage.worksPrice))
      ? 0
      : parseInt(localStorage.worksPrice);

    const age: number = isNaN(parseInt(localStorage.age))
      ? 0
      : parseInt(localStorage.age);

    const ageCoBorrowerValue: number = isNaN(
      parseInt(localStorage.ageCoBorrowerValue)
    )
      ? 0
      : parseInt(localStorage.ageCoBorrowerValue);

    const estateType: number = isNaN(parseInt(localStorage.estateType))
      ? 0
      : parseInt(localStorage.estateType);

    return {
      monthlyIncomes: monthlyIncomes,
      mainBorrowerIncomes: mainBorrowerIncomes,
      coBorrowerIncomes: coBorrowerIncomes,
      monthlyCredits: monthlyCredits,
      personalContribution: personalContribution,

      constructPrice: constructPrice,
      landPrice: landPrice,
      price: price,
      worksPrice: worksPrice,
      age: age,
      ageCoBorrowerValue: ageCoBorrowerValue,
      estateType: estateType,
    };
  };

  public generateCapacityInfos = (
    incomes: number,
    monthlyCredit: number,
    personalContribution: number,
    monthlyPayment: number,
    duration: number,
    type: 0 | 1 | 2 | 3,
    rates: Array<IBankRates>,
    estateType: number,
    constructPrice: number,
    landPrice: number,
    price: number,
    worksPrice: number,
    age: number,
    ageCoBorrowerValue: number

  ) => {

    const rate: number = bankRatesService.getRateByIncomes(
      (incomes || 0) * 12,
      duration || 25,
      rates
    );
    const debtCapacity: number = incomes
      ? this.calculateDebtCapacity(incomes || 0, monthlyCredit || 0)
      : 0;
    const borrowingCapital: number =
      monthlyPayment && duration
        ? this.getBorrowingCapital(monthlyPayment, duration * 12, rate)
        : 0;
    const buyingCapacity: number =
      type !== undefined
        ? this.getBuyingCapacity(
            borrowingCapital,
            personalContribution || 0,
            type
          )
        : 0;
    const globalFees: number = this.getFeeByType(
      borrowingCapital + (personalContribution || 0),
      type
    );

    const ownerInsuranceRate: number = 0;
    const coInsuranceRate: number = 0;
    const ownerInsurance: number = 0;
    const coInsurance: number = 0;

    const insurance: number = ownerInsurance + coInsurance;
    const monthlyAmountWithoutInsurance: number =
      Math.min(monthlyPayment || 0, debtCapacity) - insurance;
    const borrowingCapitalWithInsurance: number =
      this.getBorrowingCapital(
        monthlyAmountWithoutInsurance,
        duration * 12,
        rate
      ) || 0;
    const buyingCapacityWithInsurance: number =
      type !== undefined
        ? this.getBuyingCapacity(
            borrowingCapitalWithInsurance,
            personalContribution,
            type
          )
        : 0;
    const globalFeesIncludingInsurance = this.getFeeByType(
      borrowingCapitalWithInsurance + (personalContribution || 0),
      type
    );

    return {
      debtCapacity: debtCapacity,
      monthlyCredits: monthlyCredit || 0,
      monthlyIncome: incomes || 0,
      personalContribution: personalContribution || 0,
      duration: duration || 25,
      type: type || 0,
      monthlyAmount: Math.min(monthlyPayment || 0, debtCapacity),
      buyingCapacity: buyingCapacity || 0,
      amountBorrowed: borrowingCapital || 0,
      creditCost: 0,
      warrantyFees: 0,
      globalFees,
      newGlobalFees: globalFeesIncludingInsurance,
      rate: rate,
      mainBorrowerInsurance: ownerInsurance,
      coBorrowerInsurance: coInsurance,
      mainBorrowerInsuranceRate: ownerInsuranceRate,
      coBorrowerInsuranceRate: coInsuranceRate,
      borrowingCapitalWithInsurance: borrowingCapitalWithInsurance,
      result: buyingCapacityWithInsurance,
      estateType: estateType,
      constructPrice: constructPrice,
      landPrice: landPrice,
      price: price,
      worksPrice: worksPrice,
      age: age,
      ageCoBorrowerValue: ageCoBorrowerValue,
    };
  };
}

export default new capacityService();
