import React from "react";
import "../scss/OnboardingIncomes.scss";

/** ASSETS */
import { ReactComponent as RevenusIcon } from "assets/svg/ic-picto-revenus.svg";

/** COMPONENTS */
import OnboardingInputCard from "components/common/OnboardingInputCard/OnboardingInputCard.component";
import OnboardingAddCoBorrower from "./OnboardingAddCoBorrower.component";
import OnboardingNextButton from "components/common/OnboardingNextButton/OnboardingNextButton.component";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** STORE */
import {
	selectStoreOnboardingIncomes,
	selectStoreOnboardingIsCoBorrower,
	selectStoreOnboardingBorrowerIncomes,
	updateStoreOnboarding,
} from "features/onboarding/onboardingSlice";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";
import { numberWithoutSpaces } from "utils/numberWithoutSpaces";
import OnboardingInfosButton from "components/common/OnboardingInfosButton/OnboardingInfosButton.component";

const OnboardingIncomes: React.FC = () => {
	const isCoBorrower: boolean = useAppSelector(
		selectStoreOnboardingIsCoBorrower
	);
	const incomes: number | null = useAppSelector(selectStoreOnboardingIncomes);
	const borrowerIncomes: number | null = useAppSelector(
		selectStoreOnboardingBorrowerIncomes
	);
	const dispatch = useAppDispatch();

	const handleChange = (event: any): void => {
		if (event.target.name === "incomes") {
			dispatch(
				updateStoreOnboarding({
					incomes: numberWithoutSpaces(event.target.value),
				})
			);
		}
		if (event.target.name === "borrowerIncomes") {
			dispatch(
				updateStoreOnboarding({
					borrowerIncomes: numberWithoutSpaces(event.target.value),
				})
			);
		}
	};

	const handleDeleteCoBorrower = (): void => {
		dispatch(
			updateStoreOnboarding({
				borrowerIncomes: null,
				isCoBorrower: null,
			})
		);
	};

	return (
		<div className="column onboarding-incomes">
			<h1>Tout d’abord, quels sont vos revenus mensuels ?</h1>

			<div className="flex input-cards">
				<OnboardingInputCard
					label={"Emprunteur"}
					inputName="incomes"
					icon={<RevenusIcon />}
					units={["€", "/mois"]}
					value={incomes === null ? "" : numberWithSpaces(incomes)}
					CBHandleChange={handleChange}
				/>

				{isCoBorrower ? (
					<OnboardingInputCard
						label="Co-emprunteur"
						inputName="borrowerIncomes"
						icon={<RevenusIcon />}
						units={["€", "/mois"]}
						value={
							borrowerIncomes === null ? "" : numberWithSpaces(borrowerIncomes)
						}
						CBHandleChange={handleChange}
						CBHandleClose={() => handleDeleteCoBorrower()}
					/>
				) : (
					<OnboardingAddCoBorrower />
				)}
			</div>

			{/* <div className="column"> */}
				<OnboardingNextButton />
				<OnboardingInfosButton />
			{/* </div> */}
		</div>
	);
};

export default OnboardingIncomes;
