import React from "react";
import "./scss/ColoredResultCard.scss";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";

import { ReactComponent as Chevron } from "assets/svg/bold-right-chevron.svg";

interface IProps {
	title: string;
	value: number;
	backgroundColor?: string;
	CBHandleClick: () => void;
}

const ColoredResultCard: React.FC<IProps> = ({
	title,
	value,
	backgroundColor = "#e9f2ff",
	CBHandleClick,
}) => {
	return (
		<div
			className="colored-result-card"
			style={{ backgroundColor: backgroundColor }}
		>
			<button onClick={CBHandleClick} className="flex space-between container">
				<div className="column">
					<span className="title">{title}</span>
					<span className="value">{numberWithSpaces(value)} €</span>
				</div>
				<div className="flex details">
					<span>DETAILS </span>
					<Chevron />
				</div>
			</button>
		</div>
	);
};

export default ColoredResultCard;
