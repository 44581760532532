import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

/** INTERFACES */

const initialState: any = {
	monthlyIncomes: 0,
	mainBorrowerIncomes: 0,
	coBorrowerIncomes: 0,
	monthlyCredits: 0,
	personalContribution: 0,
	duration: 25,
	type: 0,
	creditCost: 0,
	debtCapacity: 0,
	monthlyPayment: 0,
	contribution: 0,
	result: 0,
	newGlobalFees: 0,
	borrowingCapitalWithInsurance: 0,
};

export const capacitySlice = createSlice({
	name: "capacity",
	initialState,
	reducers: {
		updateStoreCapacity: (state: any, action: PayloadAction<any | any>) => {
			return { ...state, ...action.payload };
		},
	},
});

export const { updateStoreCapacity } = capacitySlice.actions;

export const selectStoreCapacity = (state: RootState) => state.capacity;
export const selectStoreCapacityEstateType = (state: RootState) => state.capacity.type;
export const selectStoreCapacityDebtCapacity = (state: RootState) => state.capacity.debtCapacity;
export const selectStoreCapacityMonthlyAmount = (state: RootState) => state.capacity.monthlyAmount;

export default capacitySlice.reducer;
