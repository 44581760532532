export enum EFirebaseEvents {
  HOMEPAGE_TEST_BUTTON = "apimo_webapp_test",
  ONBOARDING_INCOMES_VALIDATION = "apimo_webapp_onboarding_incomes",
  ONBOARDING_HOW_INCOMES = "apimo_webapp_onboarding_how_incomes",
  ONBOARDING_HOW_INCOMES_NO = "apimo_webapp_onboarding_how_incomes_no",
  ONBOARDING_HOW_INCOMES_YES = "apimo_webapp_onboarding_how_incomes_yes",
  ONBOARDING_CREDITS_NO = "apimo_webapp_onboarding_credits_no",
  ONBOARDING_CREDITS_YES = "apimo_webapp_onboarding_credits_yes",
  ONBOARDING_CREDITS_VALIDATION = "apimo_webapp_onboarding_credits",
  ONBOARDING_HOW_CREDITS = "apimo_webapp_onboarding_how_credits",
  ONBOARDING_HOW_CREDITS_NO = "apimo_webapp_onboarding_how_credits_no",
  ONBOARDING_HOW_CREDITS_YES = "apimo_webapp_onboarding_how_credits_yes",
  ONBOARDING_CONTRIBUTION_VALIDATION = "apimo_webapp_onboarding_contribution",
  ONBOARDING_HOW_CONTRIBUTION = "apimo_webapp_onboarding_how_contribution",
  ONBOARDING_HOW_CONTRIBUTION_NO = "apimo_webapp_onboarding_how_contribution_no",
  ONBOARDING_HOW_CONTRIBUTION_YES = "apimo_webapp_onboarding_how_contribution_yes",
  ONBOARDING_LEAVE_PRESSED = "apimo_webapp_onboarding_leave_pressed",
  CAPACITY_DETAILS = "apimo_webapp_capacity_detail",
  CAPACITY_DETAILS_RATE_PRESSED = "apimo_webapp_capacity_rate_pressed",
  CAPACITY_SAVE = "apimo_webapp_capacity_save",
  CAPACITY_INCOMES_UPDATE = "apimo_webapp_capacity_incomes_update",
  CAPACITY_CREDITS_UPDATE = "apimo_webapp_capacity_credits_update",
  CAPACITY_CONTRIBUTION_UPDATE = "apimo_webapp_capacity_contribution_update",
  SEND_LINK = "apimo_webapp_send_link_pressed",
}