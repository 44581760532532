import React, { useState } from "react";
import "./scss/PropertyTypeSelector.scss";

/** ASSETS */
import { ReactComponent as ChevronDown } from "assets/svg/chevron-down.svg";

/** COMPONENTS */
import PropertyTypeSelectorModal from "../PropertyTypeSelectorModal/PropertyTypeSelectorModal.component";

/** CONSTANTS */
import { CAllPropertyTypes } from "constants/CAllPropertyTypes.constant";

/** HOOKS */
import { useAppSelector } from "hooks";

/** INTERFACES */
import { IPropertyTypeNameIcon } from "interfaces/IPropertyTypeNameIcon.interface";

/** LODASH */
import { find } from "lodash";

/** STORE */
import { selectStoreCapacityEstateType } from "features/capacity/capacitySlice";

interface IProps {
	CBHandleChange: (newType: number | null) => void;
}

const PropertyTypeSelector: React.FC<IProps> = ({ CBHandleChange }) => {
	const [displayModal, setDisplayModal] = useState<boolean>(false);
	const estateType: number | null = useAppSelector(
		selectStoreCapacityEstateType
	);

	const handleChange = (newType: number | null) => {
		console.log('HANDLE CHANGE', newType);
		CBHandleChange(newType);
		setDisplayModal(false);
	};

	const displayEstateTypeName = () => {
		console.log('displayEstateTypeName', estateType);
		const propertyType: IPropertyTypeNameIcon | any = find(CAllPropertyTypes, {
			type: estateType,
		});
		let name: string = "";

		if (propertyType != null) {
			name = propertyType.title;
		}

		return <span className="type">{name}</span>;
	};

	return (
		<div className="property-type-selector">
			<div className="title">
				<span>Type de bien</span>
			</div>

			<button
				className="flex space-between"
				onClick={() => setDisplayModal(true)}
			>
				{displayEstateTypeName()}
				<ChevronDown />
			</button>

			<PropertyTypeSelectorModal
				CBHandleChange={handleChange}
				CBHandleClose={() => setDisplayModal(false)}
				open={displayModal}
			/>
		</div>
	);
};

export default PropertyTypeSelector;
