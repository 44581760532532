import React, { useEffect, useState } from "react";
import "../scss/OnboardingBirthday.scss";

/** ASSETS */
import { ReactComponent as BirthdayIcon } from "assets/svg/cake-birthday.svg";

/** COMPONENTS */
import OnboardingInputBirthday from "components/common/OnboardingInputBirthday/OnboardingInputBirthday.component";
import OnboardingAddCoBorrower from "./OnboardingFundingAddCoBorrower.component";
import OnboardingNextButton from "components/common/OnboardingFundingNextButton/OnboardingFundingNextButton.component";
// import OnboardingInfosButton from "components/common/OnboardingInfosButton/OnboardingInfosButton.component";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** STORE */
import {
  // selectStoreOnboardingIncomes,
  // selectStoreOnboardingBorrowerIncomes,
  // selectStoreOnboardingAgeCoborrower,
  updateStoreOnboarding,
  selectStoreOnboardingAge,
  selectStoreOnboardingAgeCoborrowerValue,
  selectStoreOnboardingIsCoBorrower,
} from "features/onboarding/onboardingFundingSlice";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";
import { numberWithoutSpaces } from "utils/numberWithoutSpaces";

const OnboardingBirthday: React.FC = () => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isEmpty1, setIsEmpty1] = useState(false);
  const [isClicked1, setIsClicked1] = useState(false);
  const isCoBorrower: boolean = useAppSelector(
    selectStoreOnboardingIsCoBorrower
  );
  const age: number | null = useAppSelector(selectStoreOnboardingAge);
  // const ageCoborrower: boolean = useAppSelector(
  //   selectStoreOnboardingAgeCoborrower
  // );
  const ageCoborrowerValue: number | null = useAppSelector(
    selectStoreOnboardingAgeCoborrowerValue
  );
  const dispatch = useAppDispatch();

  const handleChange = (event: any): void => {
    if (event.target.name === "age") {
      dispatch(
        updateStoreOnboarding({
          age: numberWithoutSpaces(event.target.value),
        })
      );
    }
    if (event.target.name === "ageCoBorrowerValue") {
      dispatch(
        updateStoreOnboarding({
          ageCoBorrowerValue: numberWithoutSpaces(event.target.value),
        })
      );
    }
  };

  const handleDeleteCoBorrower = (): void => {
    dispatch(
      updateStoreOnboarding({
        ageCoBorrowerValue: null,
        isCoBorrower: false,
      })
    );
    setIsEmpty1(false);
    setIsClicked1(false);
  };
  useEffect(() => {
    setIsClicked(false);
    setIsClicked1(false);
    
    if (isCoBorrower === true) {
      if (age === null ) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
        setIsClicked(false);
      }
      if (ageCoborrowerValue === null) {
        setIsEmpty1(true);
      } else {
        setIsEmpty1(false);
        setIsClicked1(false);
      }
    } 
	if (isCoBorrower === false) {
      if (age === null) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
        setIsClicked(false);
      }
    }
  }, [age, ageCoborrowerValue, isCoBorrower]);

  return (
    <div className="column onboarding-incomes">
      <h1>
        On y est presque ... <br /> Quel est votre âge ?
      </h1>

      <div className="flex input-cards ">
        <OnboardingInputBirthday
          label={"Emprunteur"}
          inputName="age"
          icon={<BirthdayIcon />}
          units={["ans"]}
          value={age === null ? "" : numberWithSpaces(age)}
          CBHandleChange={handleChange}
          isClicked={isClicked}
        />

        {isCoBorrower ? (
          <OnboardingInputBirthday
            label="Co-emprunteur"
            inputName="ageCoBorrowerValue"
            icon={<BirthdayIcon />}
            units={["ans"]}
            value={
              ageCoborrowerValue === null
                ? ""
                : numberWithSpaces(ageCoborrowerValue)
            }
            CBHandleChange={handleChange}
            CBHandleClose={() => handleDeleteCoBorrower()}
            isClicked={isClicked1}
          />
        ) : (
          <OnboardingAddCoBorrower />
        )}
      </div>

      {/* <div className="column"> */}
      <OnboardingNextButton isEmpty={isEmpty} setIsClicked={setIsClicked} isEmpty1={isEmpty1} setIsClicked1={setIsClicked1}/>
      {/* </div> */}
    </div>
  );
};

export default OnboardingBirthday;
