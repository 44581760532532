/** INTERFACES */
import { IOnboardingHelpModalData } from "../interfaces/IOnboardingHelpModalData.interface";

export const COnboardingHelpModalData: Array<IOnboardingHelpModalData> = [
	{
		title: "Pour calculer vos revenus mensuels",
		subtitle: "Vous devez prendre en compte :",
		listTitle: null,
		list: [
			{
				title: "Revenus de tous les emprunteurs",
				content: "(salaire, retraite, revenus fonciers, …).",
			},
			{
				title: "Pour les salariés :",
				content: "le net avant impôt.",
			},
			{
				title: "Pour les revenus fonciers :",
				content: "70% des revenus mensuels bruts.",
			},
		],
		question: "Vous êtes indépendant ?",
		answer:
			"Pas de prise de tête, notez simplement votre revenu mensuel moyen des trois dernières années.",
	},
	{
		title: "Vous avez des crédits en cours ?",
		subtitle:
			"Si vous continuez à les rembourser après votre achat, indiquez ici la totalité des mensualités.",
		listTitle: "Voici des exemples de crédits : ",
		list: [
			{
				title: "Crédit à la consommation",
				content: "",
			},
			{
				title: "Crédit maison, appartement",
				content: "",
			},
			{
				title: "Crédit voiture",
				content: "",
			},
		],
		question: null,
		answer: null,
	},
	{
		title: "Votre apport personnel",
		subtitle:
			"L'apport personnel est la somme d'argent que vous mobilisez pour votre projet immobilier.",
		listTitle: "L'apport servira à couvrir :",
		list: [
			{
				title: "Les frais de notaires",
				content: "",
			},
			{
				title: "Les frais bancaires",
				content: "",
			},
		],
		question: "",
		answer:
			"Pensez à garder un peu d'épargne en cas de pépin. Attention, ne pas fournir d'apport peut être un élément bloquant auprès des banques.",
	},
];
