import React, { useState } from "react";
import "./scss/PhoneEmailLinkInput.scss";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import { logEvent } from "firebase/analytics";
import analytics from "firebase-config";

/** REACT INPUT MASK */
import InputMask from "react-input-mask";

/** SVGS */
import { ReactComponent as Arrow } from "assets/svg/white-arrow.svg";

interface IProps {
  error: string | null;
  onSubmit: (phone: string) => void;
  activeTab: any;
}

const PhoneEmailLinkInput: React.FC<IProps> = ({
  error,
  onSubmit,
  activeTab,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const handleChangePhone = (event: any) => {
    setPhoneNumber(event.target.value);
  };

  const handleChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const handleClick = async () => {
    logEvent(analytics, EFirebaseEvents.SEND_LINK);
    if (activeTab === "Téléphone") {
      onSubmit(phoneNumber);
    } else {
      onSubmit(email);
    }
  };

  const firstLetter = "/(?!.*[DFIOQU])[A-VXY]/i";
  const letter = "/(?!.*[DFIOQU])[A-Z]/i";
  const digit = "/[0-9]/";
  const mask = [firstLetter, digit, letter, " ", digit, letter, digit];

  return (
    <div className="flex space-between phone-input-wrapper">
      <div className="flex space-between phone-number-link-input-custom">
        {activeTab === "Téléphone" && (
          <>
            <InputMask
              mask="99 99 99 99 99"
              placeholder="Mon numéro de téléphone"
              onChange={handleChangePhone}
              type='text'
            />
          </>
        )}
        {activeTab === "Email" && (
          <>
            <InputMask
              mask={mask}
              placeholder="Mon email"
              onChange={handleChangeEmail}
              type='text'
              autoCapitalize="none"
            />
          </>
        )}
      </div>
      <button className="yellow-bg-button" onClick={handleClick}>
        <Arrow />
      </button>
      {error && <span className="error-message">{error}</span>}
    </div>
  );
};

export default PhoneEmailLinkInput;
