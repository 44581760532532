import React, { useEffect, useState } from "react";
import "../scss/OnboardingContribution.scss";

/** ASSETS */
import { ReactComponent as ContributionIcon } from "assets/svg/contribution-icon.svg";

/** COMPONENTS */
import OnboardingInputCard from "components/common/OnboardingFundingInputCard/OnboardingFundingInputCard.component";
import OnboardingFundingInfosButton from "components/common/OnboardingFundingInfosButton/OnboardingFundingInfosButton.component";
import OnboardingNextButton from "components/common/OnboardingFundingNextButton/OnboardingFundingNextButton.component";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** STORE */
import {
	// selectStoreOnboardingIncomes,
	selectStoreOnboardingPersonalContribution,
	updateStoreOnboarding,
} from "features/onboarding/onboardingFundingSlice";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";
import { numberWithoutSpaces } from "utils/numberWithoutSpaces";

const OnboardingContribution: React.FC = () => {
	// const incomes: number | null = useAppSelector(selectStoreOnboardingIncomes);
	const [isEmpty, setIsEmpty] = useState(false);
	const [isClicked, setIsClicked] = useState(false);
	const personalContribution: number | null = useAppSelector(
		selectStoreOnboardingPersonalContribution
	);
	const dispatch = useAppDispatch();

	const handleChange = (event: any): void => {
		console.log( "event.target.value", event.target.value,);
		dispatch(
			updateStoreOnboarding({
				[event.target.name]: numberWithoutSpaces(event.target.value),
			})
		);
	};

	useEffect(() => {
		if (personalContribution === null ) {
			setIsEmpty(true);
		} else {
			setIsEmpty(false);
			setIsClicked(false);
		}
	}, [personalContribution]);
	return (
		<div className="column content-center onboarding-contribution">
			<h1>Enfin, quel est le montant de votre apport ?</h1>

			<OnboardingInputCard
				label={""}
				inputName="personalContribution"
				icon={<ContributionIcon />}
				units={["€"]}
				isClicked={isClicked}
				value={
					personalContribution === null
						? ""
						: numberWithSpaces(personalContribution)
				}
				CBHandleChange={handleChange}
			/>

			<OnboardingNextButton  isEmpty={isEmpty} setIsClicked={setIsClicked} />
      <OnboardingFundingInfosButton />
		</div>
	);
};

export default OnboardingContribution;
