import React from "react";
import "./scss/OnboardingNextButton.scss";

/** ASSETS */
import { ReactComponent as Arrow } from "assets/svg/white-right-arrow.svg";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import analytics from "firebase-config";
import { logEvent } from "@firebase/analytics";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** REACT ROUTER DOM */
import { useHistory } from "react-router-dom";

/** SERVICES */
import onboardingService from "services/onboarding.service";

/** STORE */
import {
	onboardingAddSteps,
	selectStoreOnboarding,
	selectStoreOnboardingStep,
} from "features/onboarding/onboardingSlice";

const OnboardingNextButton: React.FC = () => {
	let history = useHistory();
	const onboardingData = useAppSelector(selectStoreOnboarding)
	const actualStep: number = useAppSelector(selectStoreOnboardingStep);
	const dispatch = useAppDispatch();

	const handleClick = (): void => {
		if (actualStep === 2) {
			logEvent(analytics, EFirebaseEvents.ONBOARDING_CONTRIBUTION_VALIDATION);
			onboardingService.saveOnboardingOnLocalStorage(onboardingData)
			history.push("/capacity");
		} else {
			if (actualStep === 0) {
				logEvent(analytics, EFirebaseEvents.ONBOARDING_INCOMES_VALIDATION);
			}
			if (actualStep === 1) {
				logEvent(analytics, EFirebaseEvents.ONBOARDING_CREDITS_VALIDATION);
			}
			dispatch(onboardingAddSteps(1));
		}
	};

	return (
		<div className="flex content-center onboarding-next-button">
			<button className="flex content-center items-center" onClick={handleClick}>
				<span>Continuer</span>

				<Arrow />
			</button>
		</div>
	);
};

export default OnboardingNextButton;
