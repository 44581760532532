export const numberWithoutSpaces = (numberToCast: string | number): number => {
  let numberToReturn: number = 0;
  
  numberToCast = numberToCast.toString().replace(/\s/g,'');

  if (numberToCast.indexOf('.') !== -1) {
    numberToReturn = parseFloat(numberToCast);
  } else {
    numberToReturn = parseInt(numberToCast)
  }

  if (Number.isNaN(numberToReturn)) {
    return 0;
  }
  return numberToReturn
};
