import React from "react";
import "../scss/DownloadAppCarousel.scss";

import Carousel from "@brainhubeu/react-carousel";
import '@brainhubeu/react-carousel/lib/style.css';

import Slide1 from "assets/download-carousel/story-SLIDE_1.jpg";
import Slide2 from "assets/download-carousel/story-SLIDE_2.jpg";
import Slide3 from "assets/download-carousel/story-SLIDE_3.jpg";
import Slide4 from "assets/download-carousel/story-SLIDE_4.jpg";
import Slide5 from "assets/download-carousel/story-SLIDE_5.jpg";
import Slide6 from "assets/download-carousel/story-SLIDE_6.jpg";
import Slide7 from "assets/download-carousel/story-SLIDE_7.jpg";
import Slide8 from "assets/download-carousel/story-SLIDE_8.jpg";
import Slide9 from "assets/download-carousel/story-SLIDE_9.jpg";


const DownloadAppCarousel: React.FC = () => {
	return (
		<div className="download-app-carousel">
			<Carousel plugins={["arrows", "infinite"]} animationSpeed={300} >
				<img src={Slide1} alt="carousel_slide1"/>
				<img src={Slide2} alt="carousel_slide2"/>
				<img src={Slide3} alt="carousel_slide3"/>
				<img src={Slide4} alt="carousel_slide4"/>
				<img src={Slide5} alt="carousel_slide5"/>
				<img src={Slide6} alt="carousel_slide6"/>
				<img src={Slide7} alt="carousel_slide7"/>
				<img src={Slide8} alt="carousel_slide8"/>
				<img src={Slide9} alt="carousel_slide9"/>
			</Carousel>
		</div>
	);
};

export default DownloadAppCarousel;
