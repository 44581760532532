/** ASSETS */
import { ReactComponent as IncomesIcon } from "assets/svg/ic-picto-revenus.svg";
import { ReactComponent as CreditIcon } from "assets/svg/credit-icon.svg";
import { ReactComponent as ContributionIcon } from "assets/svg/contribution-icon.svg";

export const CInputValueBlockModalContent: any = {
  mainBorrowerIncomes: {
    eventName: "INCOMES",
    label: "Emprunteur",
    inputName: "mainBorrowerIncomes",
    units: ["€", "/mois"],
    icon: <IncomesIcon />
  },
  coBorrowerIncomes: {
    eventName: "INCOMES",
    label: "Co-emprunteur",
    inputName: "coBorrowerIncomes",
    units: ["€", "/mois"],
    icon: <IncomesIcon />
  },
  monthlyCredits: {
    eventName: "CREDITS",
    label: "Crédit",
    inputName: "monthlyCredits",
    units: ["€", "/mois"],
    icon: <CreditIcon />
  },
  personalContribution: {
    eventName: "CONTRIBUTION",
    label: "Apport",
    inputName: "personalContribution",
    units: ["€"],
    icon: <ContributionIcon />
  },
}