import React from "react";
import "./scss/OnboardingInputPropertyType.scss";

/** ASSETS */
import { ReactComponent as Close } from "assets/svg/card-input-close-icon.svg";

/** CONSTANTS */
import { CAllPropertyTypes } from "constants/CAllPropertyTypes.constant";

interface IProps {
  label: string;
  inputNameConstructPrice: string;
  inputNameNewPrice: string;
  inputNameNewWorksPrice: string;
  inputNameNewLandPrice : string;
//   icon: React.ReactElement;
  units: Array<string>;
  valueConstructPrice: string;
  valueNewPrice: string;
  valueNewWorksPrice: string;
  valueNewLandPrice: string;
  type: number;
  isClicked: boolean;
  isClicked1: boolean;
  isClicked2: boolean;
  isClicked3: boolean;
  CBHandleChange0: (event: any) => void;
  CBHandleChange1: (event: any) => void;
  CBHandleChange1Bis: (event: any) => void;
  CBHandleChange2: (event: any) => void;
  CBHandleChange2Bis: (event: any) => void;
  CBHandleChange3: (event: any) => void;
  CBHandleClose?: () => void;
}

const OnboardingInputPropertyType: React.FC<IProps> = ({
  type,
  isClicked,
  isClicked1,
  isClicked2,
  isClicked3,
  label,
  inputNameConstructPrice,
  inputNameNewPrice,
  inputNameNewWorksPrice,
  inputNameNewLandPrice ,
  units,
  valueConstructPrice,
  valueNewPrice,
  valueNewWorksPrice,
  valueNewLandPrice,
  CBHandleChange0,
  CBHandleChange1,
  CBHandleChange1Bis,
  CBHandleChange2,
  CBHandleChange2Bis,
  CBHandleChange3,
  CBHandleClose,
}) => {
  return (
    <>
      {type === 0 && (
        <div className="column onboarding-input-card-property-type height-large-input">
          <div className="flex space-between header">
            <div
              className="label"
              style={{
                background: label === "" ? "transparent" : "var(--light-grey)",
              }}
            >
              <span>{label}</span>
            </div>
            {CBHandleClose !== undefined && (
              <button onClick={CBHandleClose}>
                <Close />
              </button>
            )}
          </div>

          <div className="icon">{CAllPropertyTypes[0].icon}</div>
          <div className="input-container ">
            <span>Prix du bien</span>
            <div className={isClicked ? "flex items-center content-center isClickedError" : 'flex items-center content-center value'}>
              <input
                name={inputNameNewPrice}
                value={valueNewPrice}
                onChange={CBHandleChange0}
                type="tel"
                style={{ width: "100%" }}
              />

              {units.length > 0 && (
                <div className="units">
                  <span>{units[0]}</span>

                  {units.length === 2 && <span>{units[1]}</span>}
                </div>
              )}
            </div>
          </div>
        </div>
      )} 
	  {type === 1 && (
        <div className="column onboarding-input-card-property-type height-large-input">
          <div className="flex space-between header">
            <div
              className="label"
              style={{
                background: label === "" ? "transparent" : "var(--light-grey)",
              }}
            >
              <span>{label}</span>
            </div>
            {CBHandleClose !== undefined && (
              <button onClick={CBHandleClose}>
                <Close />
              </button>
            )}
          </div>

          <div className="icon">{CAllPropertyTypes[1].icon}</div>
          <div className="input-container ">
            <span>Prix du bien</span>
            <div className={isClicked ? "flex items-center content-center isClickedError" : 'flex items-center content-center value'}>
              <input
                name={inputNameNewPrice}
                value={valueNewPrice}
                onChange={CBHandleChange1}
                type="tel"
                style={{ width: "100%" }}
              />

              {units.length > 0 && (
                <div className="units">
                  <span>{units[0]}</span>

                  {units.length === 2 && <span>{units[1]}</span>}
                </div>
              )}
            </div>
          </div>
          <div className="input-container  ">
            <span>Prix des travaux</span>
            <div className={isClicked1 ? "flex items-center content-center isClickedError" : 'flex items-center content-center value'}>
              <input
                name={inputNameNewWorksPrice}
                value={valueNewWorksPrice}
                onChange={CBHandleChange1Bis}
                type="tel"
                style={{ width: "100%" }}
              />

              {units.length > 0 && (
                <div className="units">
                  <span>{units[0]}</span>
                  {units.length === 2 && <span>{units[1]}</span>}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
	  {type === 2 && (
        <div className="column onboarding-input-card-property-type height-large-input">
          <div className="flex space-between header">
            <div
              className="label"
              style={{
                background: label === "" ? "transparent" : "var(--light-grey)",
              }}
            >
              <span>{label}</span>
            </div>
            {CBHandleClose !== undefined && (
              <button onClick={CBHandleClose}>
                <Close />
              </button>
            )}
          </div>

          <div className="icon">{CAllPropertyTypes[2].icon}</div>
          <div className="input-container ">
            <span>Prix du terrain</span>
            <div className={isClicked2 ? "flex items-center content-center isClickedError" : 'flex items-center content-center value'}>
              <input
                name={inputNameNewLandPrice}
                value={valueNewLandPrice}
                onChange={CBHandleChange2}
                type="tel"
                style={{ width: "100%" }}
              />

              {units.length > 0 && (
                <div className="units">
                  <span>{units[0]}</span>

                  {units.length === 2 && <span>{units[1]}</span>}
                </div>
              )}
            </div>
          </div>
          <div className="input-container  ">
            <span>Prix de la construction</span>
            <div className={isClicked3 ? "flex items-center content-center isClickedError" : 'flex items-center content-center value'}>
              <input
                name={inputNameConstructPrice}
                value={valueConstructPrice}
                onChange={CBHandleChange2Bis}
                type="tel"
                style={{ width: "100%" }}
              />

              {units.length > 0 && (
                <div className="units">
                  <span>{units[0]}</span>
                  {units.length === 2 && <span>{units[1]}</span>}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
	  {type === 3 && (
        <div className="column onboarding-input-card-property-type height-small-input">
          <div className="flex space-between header">
            <div
              className="label"
              style={{
                background: label === "" ? "transparent" : "var(--light-grey)",
              }}
            >
              <span>{label}</span>
            </div>
            {CBHandleClose !== undefined && (
              <button onClick={CBHandleClose}>
                <Close />
              </button>
            )}
          </div>

          <div className="icon">{CAllPropertyTypes[3].icon}</div>
          <div className="input-container ">
            <span>Prix du bien neuf</span>
            <div className={isClicked ? "flex items-center content-center isClickedError" : 'flex items-center content-center value'}>
              <input
                name={inputNameNewPrice}
                value={valueNewPrice}
                onChange={CBHandleChange3}
                type="tel"
                style={{ width: "100%" }}
              />

              {units.length > 0 && (
                <div className="units">
                  <span>{units[0]}</span>

                  {units.length === 2 && <span>{units[1]}</span>}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OnboardingInputPropertyType;
