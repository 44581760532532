import React, { useEffect, useState } from "react";
import "../scss/OnboardingHeaderStepIndicator.scss";

interface IProps {
	step: number;
	numberOfSteps: number;
}

const OnboardingHeaderStepIndicator: React.FC<IProps> = ({
	step,
	numberOfSteps,
}) => {
	const [indicators, setIndicators] = useState<Array<boolean>>([]);

	useEffect(() => {
		const tmp: Array<boolean> = [];

		for (let i = 0; i < numberOfSteps; i++) {
			tmp.push(step === i ? true : false);
		}
		setIndicators(tmp);
	}, [step, numberOfSteps]);

	return (
		<div className="flex onboarding-header-step-indicator">
			{indicators.map((isCurrentStep: boolean, index) => {
				return (
					<div
						className={`indicator ${isCurrentStep ? "current" : "not-current"}`}
						key={index}
					/>
				);
			})}
		</div>
	);
};

export default OnboardingHeaderStepIndicator;
