import React from "react";
import "../scss/OnboardingContribution.scss";

/** ASSETS */
import { ReactComponent as ContributionIcon } from "assets/svg/contribution-icon.svg";

/** COMPONENTS */
import OnboardingInputCard from "components/common/OnboardingInputCard/OnboardingInputCard.component";
// import OnboardingAddCoBorrower from "./OnboardingAddCoBorrower.component";
import OnboardingNextButton from "components/common/OnboardingNextButton/OnboardingNextButton.component";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** STORE */
import {
	// selectStoreOnboardingIncomes,
	selectStoreOnboardingPersonalContribution,
	updateStoreOnboarding,
} from "features/onboarding/onboardingSlice";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";
import { numberWithoutSpaces } from "utils/numberWithoutSpaces";
import OnboardingInfosButton from "components/common/OnboardingInfosButton/OnboardingInfosButton.component";

const OnboardingContribution: React.FC = () => {
	// const incomes: number | null = useAppSelector(selectStoreOnboardingIncomes);
	const personalContribution: number | null = useAppSelector(
		selectStoreOnboardingPersonalContribution
	);
	const dispatch = useAppDispatch();

	const handleChange = (event: any): void => {
		console.log( "event.target.value", event.target.value,);
		dispatch(
			updateStoreOnboarding({
				[event.target.name]: numberWithoutSpaces(event.target.value),
			})
		);
	};

	return (
		<div className="column content-center onboarding-contribution">
			<h1>Enfin, quel est le montant de votre apport ?</h1>

			<OnboardingInputCard
				label={""}
				inputName="personalContribution"
				icon={<ContributionIcon />}
				units={["€"]}
				value={
					personalContribution === null
						? ""
						: numberWithSpaces(personalContribution)
				}
				CBHandleChange={handleChange}
			/>

			<OnboardingNextButton />
			<OnboardingInfosButton />
		</div>
	);
};

export default OnboardingContribution;
