import React from "react";
import "../scss/OnboardingProperty.scss";

/** ASSETS */
// import { ReactComponent as RevenusIcon } from "assets/svg/ic-picto-revenus.svg";

/** COMPONENTS */
// import OnboardingInputCard from "components/common/OnboardingInputCard/OnboardingInputCard.component";
// import OnboardingAddCoBorrower from "./OnboardingAddCoBorrower.component";
import OnboardingNextButton from "components/common/OnboardingFundingNextButton/OnboardingFundingNextButton.component";
import OnboardingPropertyTypeSelector from "components/common/OnboardingPropertyTypeSelector/OnboardingPropertyTypeSelector.component";

/** HOOKS */
import { useAppDispatch } from "hooks";

/** STORE */
import {
	// selectStoreOnboardingIncomes,
	// selectStoreOnboardingIsCoBorrower,
	// selectStoreOnboardingBorrowerIncomes,
	updateStoreOnboarding,
} from "features/onboarding/onboardingFundingSlice";

import // selectStoreCapacity,
// updateFundingStoreCapacity,
"features/capacity/capacityFundingSlice";

/** UTILS */
// import { numberWithSpaces } from "utils/numberWithSpaces";
// import { numberWithoutSpaces } from "utils/numberWithoutSpaces";

const OnboardingHousing: React.FC = () => {
	// const isCoBorrower: boolean = useAppSelector(
	//   selectStoreOnboardingIsCoBorrower
	// );
	// const incomes: number | null = useAppSelector(selectStoreOnboardingIncomes);
	// const borrowerIncomes: number | null = useAppSelector(
	//   selectStoreOnboardingBorrowerIncomes
	// );
	const dispatch = useAppDispatch();
	// const [displayModal, setDisplayModal] = useState<boolean>(false);

	// const handleChange = (event: any): void => {
	//   if (event.target.name === "incomes") {
	//     dispatch(
	//       updateStoreOnboarding({
	//         incomes: numberWithoutSpaces(event.target.value),
	//       })
	//     );
	//   }
	//   if (event.target.name === "borrowerIncomes") {
	//     dispatch(
	//       updateStoreOnboarding({
	//         borrowerIncomes: numberWithoutSpaces(event.target.value),
	//       })
	//     );
	//   }
	// };

	// const handleDeleteCoBorrower = (): void => {
	//   dispatch(
	//     updateStoreOnboarding({
	//       borrowerIncomes: null,
	//       isCoBorrower: null,
	//     })
	//   );
	// };

	const handleEstateTypeChange = (newEstateType: number | null) => {
		dispatch(updateStoreOnboarding({ estateType: newEstateType }));
	};

	return (
		<div className="column onboarding-incomes">
			<h1>Quel type de bien voulez-vous acheter ?</h1>

			<div className="flex input-cards">
				<OnboardingPropertyTypeSelector
					CBHandleChange={handleEstateTypeChange}
					// CBHandleClose={() => setDisplayModal(false)}
					CBHandleClose={() => {}}
					open={true}
				/>
			</div>

			<OnboardingNextButton />
		</div>
	);
};

export default OnboardingHousing;
