import React from "react";
import "./scss/ColoredFundingResultCard.scss";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";

import { ReactComponent as Chevron } from "assets/svg/bold-right-chevron.svg";

interface IProps {
  title: string;
  subtitle: string;
  value: number;
  backgroundColor?: string;
  CBHandleClick: () => void;
  status?: number;
}

const ColoredResultCard: React.FC<IProps> = ({
  title,
  subtitle,
  value,
  backgroundColor = "#e9f2ff",
  CBHandleClick,
  status,
}) => {
  return (
    <div
      className="colored-result-card-funding"
    >
      <button onClick={CBHandleClick} className="flex space-between container">
        <div className="details-result-wrapper">
          <span className="title">{title}</span>
          <span className="subtitle">{subtitle}</span>
          <span className="value-funding">
            {
              isNaN(value)
              ?
              '-'
              :
              numberWithSpaces(value)
            } €<span className="billing">/mois</span>

          </span>
        </div>
        <div className="details-right-wrapper">
          <div className="flex details-funding">
            <span>DETAILS </span>
            <Chevron />
          </div>
          {status === 0 && (
            <div className="hard-funding">
              <span>Financement difficile</span>
            </div>
          )}
          {status === 1 && (
            <div className="easy-funding">
              <span>Financement facile </span>
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

export default ColoredResultCard;
