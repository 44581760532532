import React from "react";
import "./scss/OnboardingScreen.scss";

/** COMPONENTS */
import OnboardingNavigator from "components/OnboardingNavigator/OnboardingNavigator.component";

const OnboardingScreen: React.FC = () => {

	return (
		<div id="onboarding" className="flex">
			<OnboardingNavigator />
		</div>
	);
};

export default OnboardingScreen;
