import React, { useEffect, useState } from "react";
import "../scss/OnboardingFundingIncomes.scss";

/** ASSETS */
import { ReactComponent as RevenusIcon } from "assets/svg/ic-picto-revenus.svg";

/** COMPONENTS */
import OnboardingInputCard from "components/common/OnboardingFundingInputCard/OnboardingFundingInputCard.component";
import OnboardingAddCoBorrower from "./OnboardingFundingAddCoBorrower.component";
import OnboardingNextButton from "components/common/OnboardingFundingNextButton/OnboardingFundingNextButton.component";
import OnboardingFundingInfosButton from "components/common/OnboardingFundingInfosButton/OnboardingFundingInfosButton.component";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** STORE */
import {
	selectStoreOnboardingIncomes,
	selectStoreOnboardingIsCoBorrower,
	selectStoreOnboardingBorrowerIncomes,
	updateStoreOnboarding,
} from "features/onboarding/onboardingFundingSlice";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";
import { numberWithoutSpaces } from "utils/numberWithoutSpaces";

const OnboardingIncomes: React.FC = () => {
	const [isEmpty, setIsEmpty] = useState(false);
	const [isClicked, setIsClicked] = useState(false);
	const [isEmpty1, setIsEmpty1] = useState(false);
	const [isClicked1, setIsClicked1] = useState(false);
	const isCoBorrower: boolean = useAppSelector(
		selectStoreOnboardingIsCoBorrower
	);
	const incomes: number | null = useAppSelector(selectStoreOnboardingIncomes);
	const borrowerIncomes: number | null = useAppSelector(
		selectStoreOnboardingBorrowerIncomes
	);
	const dispatch = useAppDispatch();

	const handleChange = (event: any): void => {
		if (event.target.name === "incomes") {
			dispatch(
				updateStoreOnboarding({
					incomes: numberWithoutSpaces(event.target.value),
				})
			);
		}
		if (event.target.name === "borrowerIncomes") {
			dispatch(
				updateStoreOnboarding({
					borrowerIncomes: numberWithoutSpaces(event.target.value),
				})
			);
		}
	};

	const handleDeleteCoBorrower = (): void => {
		dispatch(
			updateStoreOnboarding({
				borrowerIncomes: null,
				isCoBorrower: false,
			})
		);
		setIsEmpty1(false);
		setIsClicked1(false);
	};

	useEffect(() => {
		setIsClicked(false);
		setIsClicked1(false);

		// console.log(
		// 	"EFFECT",
		// 	incomes,
		// 	borrowerIncomes,
		// 	isCoBorrower,
		// 	isClicked,
		// 	isClicked1
		// );
		if (isCoBorrower === true) {
			if (incomes === null) {
				setIsEmpty(true);
			} else {
				setIsEmpty(false);
			}
			if (borrowerIncomes === null) {
				setIsEmpty1(true);
			} else {
				setIsEmpty1(false);
			}
		}
		if (isCoBorrower === false) {
			if (incomes === null) {
				setIsEmpty(true);
			} else {
				setIsEmpty(false);
			}
		}

		// eslint-disable-next-line
	}, [incomes, borrowerIncomes, isCoBorrower]);

	// console.log("isEmpty", isEmpty);
	return (
		<div className="column onboarding-incomes">
			<h1 className="header-onboarding">
				Tout d'abord, quels sont vos revenus mensuels ?
			</h1>

			<div className="flex input-cards">
				<OnboardingInputCard
					label={"Emprunteur"}
					inputName="incomes"
					icon={<RevenusIcon />}
					units={["€", "/mois"]}
					value={incomes === null ? "" : numberWithSpaces(incomes)}
					CBHandleChange={handleChange}
					isClicked={isClicked}
				/>

				{isCoBorrower ? (
					<OnboardingInputCard
						label="Co-emprunteur"
						inputName="borrowerIncomes"
						icon={<RevenusIcon />}
						units={["€", "/mois"]}
						value={
							borrowerIncomes === null ? "" : numberWithSpaces(borrowerIncomes)
						}
						CBHandleChange={handleChange}
						CBHandleClose={() => handleDeleteCoBorrower()}
						isClicked={isClicked1}
					/>
				) : (
					<OnboardingAddCoBorrower />
				)}
			</div>

			{/* <div className="column"> */}
			<OnboardingNextButton
				isEmpty={isEmpty}
				setIsClicked={setIsClicked}
				isEmpty1={isEmpty1}
				setIsClicked1={setIsClicked1}
			/>
			<OnboardingFundingInfosButton />
			{/* </div> */}
		</div>
	);
};

export default OnboardingIncomes;
