import React, { ReactElement } from "react";
import "./scss/DetailsFundingItem.scss";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";

interface IProps {
  icon?: ReactElement;
  title: string;
  value: number | string;
  subValue?: string;
  content?: string;
  suffix?: string;
  noBackground?: boolean;
  iconStatus?: boolean;
}

const DetailsItem: React.FC<IProps> = ({
  icon,
  iconStatus,
  title,
  value,
  subValue,
  content,
  suffix,
  noBackground = false,
}) => {
  //   console.log(icon);
  return (
    <div
      className="column details-item"
      style={{
        background: noBackground ? "transparent" : "var(--light-grey)",
        padding: noBackground ? "0px 13px 0px" : "10px 13px 15px",
      }}
    >
      {subValue && (
        <div className="flex space-between">
          <div className="flex column">
            <span className="title">{title}</span>
            <div className="flex content">
              <span
                style={{
                  color: noBackground ? "var(--grey)" : "var(--fridaa-blue)",
                  width: noBackground ? "70%" : "100%",
                }}
              >
                {content}
              </span>
            </div>
          </div>
          <div className="align-right">
            <span className="value">
              {numberWithSpaces(value)} {suffix}
            </span>
            <span className="subvalue column">{subValue}</span>
          </div>
        </div>
      )}
      {!subValue && typeof value === "number" && (
        <div>
          <div className="flex">
            {(icon && iconStatus === undefined) && <div className="icon">{icon}</div>}
            {iconStatus === true && <div className="icons">{icon}</div>}
            <span className="title">{title}</span>
            <span className="value">
              {numberWithSpaces(value)} {suffix}
            </span>
          </div>
          <div className="flex content">
            <span
              style={{
                color: noBackground ? "var(--grey)" : "var(--fridaa-blue)",
                width: noBackground ? "70%" : "100%",
              }}
            >
              {content}
            </span>
          </div>
        </div>
      )}
      {!subValue && typeof value === "string" && (
        <div>
          <div className="flex items-center space-between">
            <span className="title">{title}</span>
            {value === "Financement facile" && (
              <span className="easy-funding">{value}</span>
            )}
            {value === "Financement difficile" && (
              <span className="hard-funding">{value}</span>
            )}
          </div>
          <div className="flex content">
            <span
              style={{
                color: noBackground ? "var(--grey)" : "var(--fridaa-blue)",
                width: noBackground ? "70%" : "100%",
              }}
            >
              {content}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsItem;
