import React from "react";
import "./scss/OnboardingNavigator.scss";

/** COMPONENTS */
import OnboardingHeader from "./components/OnboardingHeader.component";
import OnboardingHelpModal from "./components/OnboardingHelpModal.component";

/** CONSTANTS */
import { COnboardingNavigator } from "./constants/COnboardingNavigator.constant";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** STORE */
import {
	selectStoreOnboardingDisplayHelp,
	selectStoreOnboardingStep,
	updateStoreOnboarding,
} from "features/onboarding/onboardingSlice";

const OnboardingNavigator: React.FC = () => {
	const dispatch = useAppDispatch();
	const onboardingStep: number = useAppSelector(selectStoreOnboardingStep);
	const displayHelp: boolean = useAppSelector(selectStoreOnboardingDisplayHelp);

	return (
		<div className="column onboarding-navigator">
			<OnboardingHeader />

			{COnboardingNavigator[onboardingStep]}

			<OnboardingHelpModal
				open={displayHelp}
				step={onboardingStep}
				CBHandleClose={() =>
					dispatch(updateStoreOnboarding({ displayHelp: false }))
				}
			/>
		</div>
	);
};

export default OnboardingNavigator;
