export const roundFor = (value?: number, num: number = 5): number => {
	if (value === undefined) {
		return 0;
	} else if (value === 0) {
		return 0;
	}

	const mod = value % num;
	if (mod < num / 2) {
		return value - mod;
	} else {
		return value + (num - mod);
	}
};
