import React from "react";
import "./scss/LoanDurationSelector.scss";

/** COMPONENTS */
import LoanDurationSelectorItem from "./components/LoanDurationSelectorItem.component";

/** CONSTANTS */
import { CLoanDurationSelectorDurations } from "./constants/CLoanDurationSelectorDurations.constant";

interface IProps {
	actualDuration: number | null;
	CBHandleClick: (duration: number) => void;
}

const LoanDurationSelector: React.FC<IProps> = ({
	actualDuration,
	CBHandleClick,
}) => {
	return (
		<div className="loan-duration-selector">
			<span className="title">Durée de l'emprunt</span>

			<div className="flex space-between items">
				{CLoanDurationSelectorDurations.map(
					(duration: number, index: number) => {
						return (
							<LoanDurationSelectorItem
								selected={actualDuration}
								value={duration}
								CBHandleClick={CBHandleClick}
								key={index}
							/>
						);
					}
				)}
			</div>
		</div>
	);
};

export default LoanDurationSelector;
