import React from "react";
import "./scss/OnboardingInfosButton.scss";

/** HOOKS */
import { useAppDispatch } from "hooks";

/** STORE */
import { updateStoreOnboarding } from "features/onboarding/onboardingSlice";

const OnboardingInfosButton: React.FC = () => {
  const dispatch = useAppDispatch();  

  const handleClick = (): void => {
    dispatch(updateStoreOnboarding({displayHelp: true}))
  }

  return (
    <div className="onboarding-infos-button">
      <button onClick={handleClick} className="flex content-center items-center">
        <span>Comment calculer ?</span>
      </button>
    </div>
  )
}

export default OnboardingInfosButton;