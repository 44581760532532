import React from "react";
import "../scss/OnboardingHeader.scss";

/** ASSETS */
import { ReactComponent as Chevron } from "assets/svg/chevron-left-white.svg";

/** COMPONENTS */
import OnboardingHeaderStepIndicator from "./OnboardingHeaderStepIndicator.component";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import analytics from "firebase-config";
import { logEvent } from "@firebase/analytics";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** REACT ROUTER */
import { useHistory } from "react-router";

/** STORE */
import {
	onboardingSubSteps,
	selectStoreOnboardingHasCredits,
	selectStoreOnboardingNumberOfSteps,
	selectStoreOnboardingStep,
	updateStoreOnboarding,
} from "features/onboarding/onboardingSlice";

const OnboardingHeader: React.FC = () => {
	const step: number = useAppSelector(selectStoreOnboardingStep);
	const numberOfSteps: number = useAppSelector(selectStoreOnboardingNumberOfSteps)
	const hasCredits: boolean | null = useAppSelector(
		selectStoreOnboardingHasCredits
	);
	const dispatch = useAppDispatch();
	let history = useHistory();

	const handleLeave = (): void => {
		logEvent(analytics, EFirebaseEvents.ONBOARDING_LEAVE_PRESSED);
		history.push("/");
	}

	const handleGoBack = (): void => {
		if (step === 0) {
			handleLeave();
			return;
		}

		if (step === 2 && hasCredits === false) {
			dispatch(updateStoreOnboarding({ hasCredits: null }));
		}
		dispatch(onboardingSubSteps(1));
	};

	return (
		<div className="flex space-between onboarding-header">
			<button className="go-back" onClick={handleGoBack}>
				<Chevron />
			</button>

			<OnboardingHeaderStepIndicator step={step} numberOfSteps={numberOfSteps} />

			<div></div>
		</div>
	);
};

export default OnboardingHeader;
