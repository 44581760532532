import "./scss/CoachingButton.scss";

/** ASSETS */
import coachesImage from "assets/png/coaches.png";

/** SVGS */
import { ReactComponent as Chevron } from "assets/svg/bold-right-chevron.svg";

const CoachingButton = () => {
  return (
    <a
        href="https://www.fridaa.co/coach-credit-apimo"
        target="_blank"
        rel="noreferrer"
        className="flex coaching-link"
    >
        <div className="coaching-link-container">
            <img className="coaches" src={coachesImage} />
            <div className="coaching-text-wrapper">
                <span className="coaching-text">
                    <span className="coaching-text-bold">Faites-vous accompagner</span>{" "}
                    par un coach crédit dès maintenant
                </span>
                <div className="flex discover-button">
                    <span>DÉCOUVRIR </span>
                    <Chevron />
                </div>
            </div>
            <Chevron />
        </div>
    </a>
  )
}

export default CoachingButton;
