import React, { useEffect } from "react";
import "./scss/OnboardingFundingScreen.scss";

/** COMPONENTS */
import OnboardingFundingNavigator from "components/OnboardingNavigator/OnboardingFundingNavigator.component";

/** HOOKS */
import useQuery from "hooks/useQuery.hook";

/** STORE */
import { useAppDispatch } from "hooks";
import { updateStoreOnboarding } from "features/onboarding/onboardingFundingSlice";

const OnboardingFundingScreen: React.FC = () => {
	const dispatch = useAppDispatch();
  	const propertyPrice = useQuery("propertyPrice");
  	const fundingId = useQuery("fundingId");

	useEffect(() => {
		if (propertyPrice && !isNaN(parseInt(propertyPrice))) {
			dispatch(updateStoreOnboarding({
				price: parseInt(propertyPrice)
			}))
		}
		if (fundingId) {
			localStorage.setItem('fundingId', fundingId)
		}
	}, [propertyPrice, fundingId, dispatch])

	return (
		<div id="onboarding" className="flex">
			<OnboardingFundingNavigator />
		</div>
	);
};

export default OnboardingFundingScreen;
