import React, { useState } from "react";
import axios from "axios";
import "../scss/DownloadFundingAppModalDesktop.scss";

/** ASSETS */
import { ReactComponent as FridaaLogoIcon } from "assets/svg/fridaa-homepage-logo.svg";
import { IoClose } from "react-icons/io5";

/** COMPONENTS */
import PhoneEmailLinkInput from "components/common/PhoneEmailLinkInput/PhoneEmailLinkInput.component";
import ScanQRCode from "components/common/ScanQRCode/ScanQRCode.component";
import DownloadAppCarousel from "./DownloadFundingAppCarousel.component";
import { toast } from "react-toastify";
import DownloadAppTab from "components/common/DownloadAppTab/DownloadAppTab.component";

import PhoneNumberLinkInput from "components/common/PhoneNumberLinkInput/PhoneNumberLinkInput.component";

/** SERVICE */
import apiService from "services/api.service";
import { useAppSelector } from "hooks";
import { selectStoreCapacity } from "features/capacity/capacityFundingSlice";

interface IProps {
  CBHandleClose: () => void;
  dataFetched: any;
}

const DownloadAppModalDesktop: React.FC<IProps> = ({
  CBHandleClose,
  dataFetched,
}) => {
  const [smsError, setSmsError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>("Email");
  const [displayDownloadApp, setDisplayDownloadApp] = useState<boolean>(true);
  const REACT_APP_FRIDAA_API_BASE_URI: string | undefined =
    process.env.REACT_APP_FRIDAA_API_BASE_URI;
    const capacity = useAppSelector(selectStoreCapacity);

  const sendSmsOrEmail = async (phoneOrEmail: string) => {
    setSmsError(null);
    let numberFrenchFormat;
    if (activeTab === "Téléphone") {
      numberFrenchFormat = phoneOrEmail.replace(phoneOrEmail[0], "+33");
    }

    if (activeTab === "Téléphone") {
      try {
        return await apiService
          .post(`${REACT_APP_FRIDAA_API_BASE_URI}/webappfundings/user`, {
            fundingId: dataFetched.fundingId,
            fundingsPassportId: null,
            phone: numberFrenchFormat
              ? numberFrenchFormat.replaceAll(" ", "")
              : "",
            firstname: null,
            lastname: null,
            firstnameCoborrower: null,
            lastnameCoborrower: null,
            age: capacity.age,
            ageCoborrower: capacity.ageCoBorrowerValue,

          })
          .then((response: any) => {
            console.log("response OK");
            setDisplayDownloadApp(false);
            if (response && response.status === 200) {
              CBHandleClose();
              toast.success("SMS envoyé ! Vous allez recevoir le lien.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                icon: undefined,
                hideProgressBar: true,
                style: { fontFamily: "BalooDa2Medium", color: "#120136" },
              });
            } else if (response.statusCode === 409) {
              setSmsError("Numéro de téléphone déjà utilisé.");
            } else if (response && response.status === 400) {
              setSmsError("Numéro de téléphone invalide.");
            } else if (!response || response.status === 500) {
              setSmsError(
                "Une erreur est survenue. Veuillez réessayer plus tard."
              );
            }
          });
      } catch (e: any) {
        if (e.response && e.response.status === 400) {
          setSmsError("Email ou numéro de téléphone invalide.");
        } else {
          setSmsError("Une erreur est survenue. Veuillez réessayer plus tard.");
        }
      }
    }

    if (activeTab === "Email") {
      try {
        return await apiService
          .post(`${REACT_APP_FRIDAA_API_BASE_URI}/webappfundings/user`, {
            fundingId: dataFetched.fundingId,
            fundingsPassportId: null,
            email: phoneOrEmail,
            firstname: null,
            lastname: null,
            firstnameCoborrower: null,
            lastnameCoborrower: null,
            age: capacity.age,
            ageCoborrower: capacity.ageCoBorrowerValue,
          })
          .then((response: any) => {
            console.log("response OK");
            setDisplayDownloadApp(false);
            if (response && response.status === 200) {
              CBHandleClose();
              toast.success("SMS envoyé ! Vous allez recevoir le lien.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                icon: undefined,
                hideProgressBar: true,
                style: { fontFamily: "BalooDa2Medium", color: "#120136" },
              });
            } else if (
              response && response.status === 400
              && response.data
              && response.data.message
              && response.data.message === "auth/email-already-exists") {
                setSmsError("Cette adresse email est déjà utilisée.");
            } else if (response && response.status === 400) {
              setSmsError("Email ou numéro de téléphone invalide.");
            } else if (!response || response.status === 500) {
              setSmsError(
                "Une erreur est survenue. Veuillez réessayer plus tard."
              );
            }
          });
      } catch (e: any) {
        console.log('e.response', e.response.data);
        if (
          e.response && e.response.status === 400
          && e.response.data
          && e.response.data.message
          && e.response.data.message === "auth/email-already-exists")
        {
          setSmsError("Cette adresse email est déjà utilisée.");
        }
        else if (e.response && e.response.status === 400) {
          setSmsError("Email ou numéro de téléphone invalide.");
        } else {
          setSmsError("Une erreur est survenue. Veuillez réessayer plus tard.");
        }
      }
    }
  };
  const sendSms = async (phone: string) => {
    try {
      setSmsError(null);
      const instance = axios.create({
        baseURL: "https://us-central1-fridaaapp.cloudfunctions.net/",
        timeout: 10000,
        headers: { "Access-Control-Allow-Origin": "*" },
      });

      const response = await instance.post("/sendLinkSMS", {
        phone: phone ? phone.replaceAll(" ", "") : "",
      });

      if (response && response.status === 200) {
        CBHandleClose();
        toast.success("SMS envoyé ! Vous allez recevoir le lien.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          icon: undefined,
          hideProgressBar: true,
          style: { fontFamily: "BalooDa2Medium", color: "#120136" },
        });
      } else if (response && response.status === 400) {
        setSmsError("Veuillez saisir un numéro de téléphone valide.");
      } else if (!response || response.status === 500) {
        setSmsError("Une erreur est survenue. Veuillez réessayer plus tard.");
      }
    } catch (e: any) {
      if (e.response && e.response.status === 400) {
        setSmsError("Veuillez saisir un numéro de téléphone valide.");
      } else {
        setSmsError("Une erreur est survenue. Veuillez réessayer plus tard.");
      }
    }
  };

  const handleClick = (e: string) => {
    setActiveTab(e);
  };

  return (
    <div className="flex download-app-modal-desktop">
      {displayDownloadApp && (
        <>
          <button className="close" onClick={CBHandleClose}>
            <IoClose color="#1E1548" style={{
                  position: 'absolute',
                }}
                size="50px" 
            />
          </button>
          <div className="left-column">
            <h2>Sauvegardez votre projet sur l'application mobile</h2>
            <div className="logo">
              <FridaaLogoIcon />
            </div>
            <DownloadAppTab
              items={["Email", "Téléphone"]}
              CBHandleClick={handleClick}
            />
            <div className="link-sentence">
              <span>
                Créez votre compte avec votre{" "}
                {activeTab === "Téléphone" ? "numéro de téléphone" : "email"}{" "}
                pour sauvegarder vos données. 
                { 
                  activeTab === "Téléphone"
                  ?
                  " (Promis, c'est pas pour vous appeler ❤️)"
                  :
                  " (Pas de spam, c'est promis ❤️)"
                }
              </span>
            </div>
            <PhoneEmailLinkInput
              onSubmit={(phoneOrEmail: string) => sendSmsOrEmail(phoneOrEmail)}
              error={smsError}
              activeTab={activeTab}
            />
          </div>
        </>
      )}
      {!displayDownloadApp && (
        <>
          <button className="close" onClick={CBHandleClose}>
            <IoClose color="#1E1548" />
          </button>
          <div className="left-column">
            <h2>Découvrez bien plus en téléchargeant l'app</h2>
            <div className="logo">
              <FridaaLogoIcon />
            </div>
            <div className="link-sentence">
              <span>vous recevrez un lien</span>
            </div>
            <PhoneNumberLinkInput
              onSubmit={(phone: string) => sendSms(phone)}
              error={smsError}
            />
            <ScanQRCode qrCodeName="download" />
          </div>
        </>
      )}
      <div className="column content-center right-column">
        <DownloadAppCarousel />
      </div>
    </div>
  );
};

export default DownloadAppModalDesktop;
