import React, { useState, useEffect } from "react";
import "./scss/PropertyTypeSelectorModal.scss";

/** ASSETS */
import { ReactComponent as ChevronDown } from "assets/svg/blue-chevron-left.svg";
import { ReactComponent as Check } from "assets/svg/white-check.svg";

/** COMPONENTS */
import EstateTypeButton from "../EstateTypeButton/EstateTypeButton.component";

/** CONSTANTS */
import { CAllPropertyTypes } from "constants/CAllPropertyTypes.constant";

/** HOOKS */
import { useAppSelector } from "hooks";

/** INTERFACES */
import { IPropertyTypeNameIcon } from "interfaces/IPropertyTypeNameIcon.interface";

/** MATERIAL UI */
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

/** STORE */
import { selectStoreCapacityEstateType } from "features/capacity/capacityFundingSlice";

interface IProps {
	open: boolean;
	CBHandleClose: () => void;
	CBHandleChange: (type: number | null) => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
	function Transition(props: any, ref: any) {
		const isDesktop: boolean = window.innerWidth > 769;

		return <Slide direction={isDesktop ? "left" : "up"} ref={ref} {...props} />;
	}
);

const PropertyTypeSelectorModal: React.FC<IProps> = ({
	open,
	CBHandleClose,
	CBHandleChange,
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [selectedType, setSelectedType] = useState<number | null>(
		useAppSelector(selectStoreCapacityEstateType)
	);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	const handleClose = () => {
		setIsOpen(false);
		CBHandleClose();
	};

	const handleChange = (newSelectedType: number) => {
		setSelectedType(newSelectedType);
	};

	const handleSubmit = (): void => {
		CBHandleChange(selectedType);
	};

	const displayHeader = () => {
		return (
			<div className="column dialog-header">
				<h2>Sélectionner un type de bien</h2>
			</div>
		);
	};

	return (
		<Dialog
			className="property-type-selector-modal"
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
		>
			<DialogContent>
				<div className="column content">
					<button className="close" onClick={handleClose}>
						<ChevronDown />
					</button>
					{displayHeader()}

					<div className="flex space-around flex-wrap">
						{CAllPropertyTypes.map(
							(item: IPropertyTypeNameIcon, index: number) => {
								return (
									<EstateTypeButton
										title={item.title}
										icon={item.icon}
										type={item.type}
										selectedType={selectedType}
										CBHandleChange={handleChange}
										key={index}
									/>
								);
							}
						)}
					</div>

					<div className="submit">
						<button className="flex content-center" onClick={handleSubmit}>
							<span>Valider</span>
							<Check />
						</button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default PropertyTypeSelectorModal;