import React from "react";
import "./scss/SliderInput.scss";

/** MATERIAL UI */
import Slider from "@material-ui/core/Slider";

interface IProps {
	label: string;
	value: number;
	minimum: number;
	maximum: number;
	CBOnChange: (value: number) => void;
	unit?: string;
}

const SliderInput: React.FC<IProps> = ({
	label,
	value,
	minimum,
	maximum,
	CBOnChange,
	unit,
}) => {
	const handleChange = (event: any, newValue: number | number[]) => {
		CBOnChange(newValue as number);
	};

	return (
		<div className="slider-input">
			<span className="label">{label}</span>

			<Slider
				value={value}
				min={minimum}
				max={maximum}
				onChange={handleChange}
				aria-labelledby="capacity-slider"
        valueLabelDisplay="on"
				step={5}
			/>

			{unit && 
				<span className="unit">{unit}</span>
			}
		</div>
	);
};

export default SliderInput;
