import React, { useState } from "react";
import "./scss/OnboardingPropertyTypeSelector.scss";

/** ASSETS */
// import { ReactComponent as ChevronDown } from "assets/svg/blue-chevron-left.svg";
// import { ReactComponent as Check } from "assets/svg/white-check.svg";

/** COMPONENTS */
import EstateTypeButton from "../EstateTypeButton/EstateTypeButton.component";

/** CONSTANTS */
import { CAllPropertyTypes } from "constants/CAllPropertyTypes.constant";

/** HOOKS */
import { useAppSelector } from "hooks";

/** INTERFACES */
import { IPropertyTypeNameIcon } from "interfaces/IPropertyTypeNameIcon.interface";

/** MATERIAL UI */
import DialogContent from "@material-ui/core/DialogContent";

/** STORE */
import { selectStoreOnboardingType } from "features/onboarding/onboardingFundingSlice";

interface IProps {
  open: boolean;
  CBHandleClose: () => void;
  CBHandleChange: (type: number | null) => void;
}

const OnboardingTypeSelector: React.FC<IProps> = ({
  open,
  CBHandleClose,
  CBHandleChange,
}) => {
  // const [isOpen, setIsOpen] = React.useState(false);
  const [selectedType, setSelectedType] = useState<number | null>(
    useAppSelector(selectStoreOnboardingType)
  );

  // useEffect(() => {
  //   setIsOpen(open);
  // }, [open]);

  const handleChange = (newSelectedType: number) => {
    // console.log(newSelectedType);
    setSelectedType(newSelectedType);
    CBHandleChange(newSelectedType);
  };

  return (
    <DialogContent>
      <div className="column content">
        <div className="grouptype-selecor flex space-around flex-wrap">
          {CAllPropertyTypes.map(
            (item: IPropertyTypeNameIcon, index: number) => {
              return (
                <EstateTypeButton
                  title={item.title}
                  icon={item.icon}
                  type={item.type}
                  selectedType={selectedType}
                  CBHandleChange={handleChange}
                  key={index}
                />
              );
            }
          )}
        </div>
      </div>
    </DialogContent>
  );
};

export default OnboardingTypeSelector;
