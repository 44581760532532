import React from "react";
import "./scss/OnboardingThumb.scss";

interface IProps {
	icon: React.ReactElement;
	color: string;
	notSelected?: boolean;
	style?: any;
	CBHandleClick: () => void;
}

const OnboardingThumb: React.FC<IProps> = ({
	icon,
	color,
	notSelected = true,
	style,
	CBHandleClick,
}) => {
	return (
		<div className="onboarding-thumb">
			<button
				onClick={CBHandleClick}
				className={color}
				style={{ opacity: notSelected ? "1" : "0.5", ...style }}
			>
				{icon}
			</button>
		</div>
	);
};

export default OnboardingThumb;
