/** INTERFACES */
import { IBankRates } from "interfaces/IBankRates.interface";
import { get } from "lodash";

/** SERVICES */
import apiService from "./api.service";

const REACT_APP_FRIDAA_API_BASE_URI: string | undefined =
	process.env.REACT_APP_FRIDAA_API_BASE_URI;

class bankRatesService {
	public fetchAll = async (): Promise<Array<IBankRates>> => {
		try {
			return await apiService
				.get(`${REACT_APP_FRIDAA_API_BASE_URI}/bankRates`)
				.then((res: any) => res.data);
		} catch (error) {
			return [];
		}
	};

	public getRateByIncomes = (
		incomeForYear: number,
		duration: number,
		rates: Array<IBankRates>
		// sendedRates?: Array<IBankRates>
	): number => {
		// const rates: Array<IBankRates> =
		// 	sendedRates !== undefined ? sendedRates : await this.fetchAll();

		return rates.reduce((total: number, item: IBankRates) => {
			if (
				incomeForYear >= item.minimum &&
				(!item.maximum || item.maximum <= 0 || incomeForYear < item.maximum)
			) {
				const durations = Object.keys(item.rates)
					.map((ds) => parseInt(ds, 10))
					.sort((a, b) => a - b)
					.reverse();
				let lastDuration;

				for (const d of durations) {
					if (duration > d) {
						break;
					}
					lastDuration = d;
				}
				return (lastDuration && get(item.rates, lastDuration) / 100) ?? 0;
			}
			return total;
		}, 0);
	};
}

export default new bankRatesService();
