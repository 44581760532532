import React from "react";
import "./scss/ScanQRCode.scss";

/** ASSETS */
import { ReactComponent as DefaultQRCode } from "assets/svg/onlink_to_hm9hh8.svg";

/** CONSTANTS */
import { CQRCodes } from "./constants/CQRCodes.constant";

/** LODASH */
import { get } from "lodash";

interface IProps {
	qrCodeName: string;
}

const ScanQRCode: React.FC<IProps> = ({ qrCodeName }) => {
	return (
		<div className="scan-qr-code">
			<span>Ou scannez le QR Code avec votre téléphone :</span>

			<div className="qr-code">
				{get(CQRCodes, qrCodeName) ?? <DefaultQRCode />}
			</div>
		</div>
	);
};

export default ScanQRCode;
