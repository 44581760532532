import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

/** INTERFACES */
import { IOnboarding } from "interfaces/IOnboarding.interface";

const initialState: IOnboarding = {
	step: 0,
	numberOfSteps: 3,
	incomes: null,
	borrowerIncomes: null,
	isCoBorrower: false,
	hasCredits: null,
	monthlyCredits: null,
	personalContribution: null,
	displayHelp: false,
	estateType: 0,
	constructPrice: null,
	landPrice: null,
	price: null,
	worksPrice: null,
	age: null,
	ageCoborrower: null
};

export const onboardingSlice = createSlice({
	name: "onboarding",
	initialState,
	reducers: {
		updateStoreOnboarding: (state: any, action: PayloadAction<any | any>) => {
			return { ...state, ...action.payload };
		},
		onboardingAddSteps: (state: any, action: PayloadAction<number>) => {
			return { ...state, step: state.step + action.payload };
		},
		onboardingSubSteps: (state: any, action: PayloadAction<number>) => {
			return { ...state, step: state.step - action.payload };
		},
	},
});

export const { updateStoreOnboarding, onboardingAddSteps, onboardingSubSteps } =
	onboardingSlice.actions;

export const selectStoreOnboarding = (state: RootState) => state.onboarding;
export const selectStoreOnboardingStep = (state: RootState): number =>
	state.onboarding.step;
export const selectStoreOnboardingNumberOfSteps = (state: RootState): number =>
	state.onboarding.numberOfSteps;
export const selectStoreOnboardingIncomes = (state: RootState): number | null =>
	state.onboarding.incomes;
export const selectStoreOnboardingBorrowerIncomes = (state: RootState): number | null =>
	state.onboarding.borrowerIncomes;
export const selectStoreOnboardingIsCoBorrower = (state: RootState): boolean =>
	state.onboarding.isCoBorrower;
export const selectStoreOnboardingHasCredits = (state: RootState): boolean | null =>
	state.onboarding.hasCredits;
export const selectStoreOnboardingMonthlyCredits = (state: RootState): number | null =>
	state.onboarding.monthlyCredits;
export const selectStoreOnboardingPersonalContribution = (state: RootState): number | null =>
	state.onboarding.personalContribution;
export const selectStoreOnboardingDisplayHelp = (state: RootState): boolean =>
	state.onboarding.displayHelp;

export default onboardingSlice.reducer;
