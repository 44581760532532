/** INTERFACES */
import { IPropertyTypeNameIcon } from "interfaces/IPropertyTypeNameIcon.interface";

/** SVGS */
import { ReactComponent as EstateOld } from "assets/svg/estate-old-icon.svg";
import { ReactComponent as EstateWithWorks } from "assets/svg/estate-with-works-icon.svg";
import { ReactComponent as EstateLand } from "assets/svg/estate-land-icon.svg";
import { ReactComponent as EstateNew } from "assets/svg/estate-new-icon.svg";

export const CAllPropertyTypes: Array<IPropertyTypeNameIcon> = [
  {
    title: "Un bien ancien",
    icon: <EstateOld />,
    type: 0,
  },
  {
    title: "Un bien ancien + travaux",
    icon: <EstateWithWorks />,
    type: 1,
  },
  {
    title: "Un terrain + construction",
    icon: <EstateLand />,
    type: 2,
  },
  {
    title: "Un bien neuf",
    icon: <EstateNew />,
    type: 3,
  },
]