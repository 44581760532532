import React, { useEffect, useState } from "react";
import "./scss/PropertyFundingTypeSelector.scss";

/** ASSETS */
import { ReactComponent as ChevronDown } from "assets/svg/chevron-down.svg";

/** COMPONENTS */
import PropertyFundingTypeSelectorModal from "../PropertyFundingTypeSelectorModal/PropertyFundingTypeSelectorModal.component";

/** CONSTANTS */
import { CAllPropertyTypes } from "constants/CAllPropertyTypes.constant";

/** HOOKS */
import { useAppSelector } from "hooks";

/** INTERFACES */
import { IPropertyTypeNameIcon } from "interfaces/IPropertyTypeNameIcon.interface";

/** LODASH */
import { find } from "lodash";

/** STORE */
import { selectStoreCapacityEstateType } from "features/capacity/capacityFundingSlice";

interface IProps {
  CBHandleChange: (newType: number | null) => void;
  projectPrice: number;
}

const PropertyTypeSelector: React.FC<IProps> = ({
  CBHandleChange,
  projectPrice,
}) => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const estateType: number | null = useAppSelector(
    selectStoreCapacityEstateType
  );
  // console.log('estateType', estateType);

  const handleChange = (newType: number | null) => {
    CBHandleChange(newType);
    setDisplayModal(false);
  };

  const propertyType: IPropertyTypeNameIcon | any = find(CAllPropertyTypes, {
    type: estateType,
  });
  // console.log("propertyType", propertyType);

  useEffect(() => {
    if (propertyType.icon) {
      setLoading(false);
    }
  }, [propertyType, estateType]);

  const displayEstateTypeName = () => {
    const propertyType: IPropertyTypeNameIcon | any = find(CAllPropertyTypes, {
      type: estateType,
    });
    let name: string = "";
    if (propertyType != null) {
      name = propertyType.title;
    }
    return (
      <span className="type-underline">
        {name} à {projectPrice} €
      </span>
    );
  };

  return (
    <div className="property-funding-type-selector">
      {!loading && (
        <button
          className="flex justify-center items-center space-between"
          onClick={() => setDisplayModal(true)}
        >
          <div className="flex justify-center items-center">
            <div className="icon-selected">{propertyType?.icon}</div>
            <span className="type-no-underline ">
              Mon bien est {displayEstateTypeName()}
            </span>
          </div>
          <ChevronDown className="chevron-down" />
        </button>
      )}
      <PropertyFundingTypeSelectorModal
        CBHandleChange={handleChange}
        CBHandleClose={() => setDisplayModal(false)}
        open={displayModal}
      />
    </div>
  );
};

export default PropertyTypeSelector;
