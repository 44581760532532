import React, { useState } from "react";
import "./scss/DetailsFundingTab.scss";

interface IProps {
  items: Array<string>;
  CBHandleClick: (name: string) => void;
}

const DetailsTab: React.FC<IProps> = ({ items, CBHandleClick }) => {
  const [activeTab, setActiveTab] = useState<string>(items[0]);
  console.log("items", items);
  return (
    <div className="flex content-center details-tab">
      <div className="container">
        <button
          onClick={() => {
            CBHandleClick("Ma capacité");
            setActiveTab("Ma capacité");
          }}
          className={activeTab === "Ma capacité" ? "mensual" : "grey"}
        >
          <span>Ma capacité</span>
        </button>
        <button
          onClick={() => {
            CBHandleClick("status");
            setActiveTab("status");
          }}
          className={activeTab === "status" ? "mensual" : "grey"}
        >
          <span>Mon statut</span>
        </button>
      </div>
    </div>
  );
};

export default DetailsTab;
