import React, { useState } from "react";
import "./scss/PhoneNumberLinkInput.scss";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import { logEvent } from "firebase/analytics";
import analytics from "firebase-config";

/** REACT INPUT MASK */
import InputMask from "react-input-mask";

/** SVGS */
import { ReactComponent as Arrow } from "assets/svg/white-bottom-arrow.svg";

interface IProps {
	error: string|null;
	onSubmit: (phone: string) => void;
}

const PhoneNumberLinkInput: React.FC<IProps> = ({
	error,
	onSubmit
}) => {
	const [phoneNumber, setPhoneNumber] = useState<string>("");

	const handleChange = (event: any) => {
		setPhoneNumber(event.target.value);
	};

	const handleClick = async () => {
		logEvent(analytics, EFirebaseEvents.SEND_LINK)
		onSubmit(phoneNumber);
    // console.log(phoneNumber);
  };

	return (
		<div className="flex space-between phone-input-wrapper">
			<div className="flex space-between phone-number-link-input">
				<InputMask
					mask="99 99 99 99 99"
					placeholder="N° téléphone"
					onChange={handleChange}
				/>
				<button className="flex content-center" onClick={handleClick}>
					<span>Envoyer</span>
					<Arrow />
				</button>
			</div>
			{
				error &&
				<span className="error-message">{error}</span>
			}
		</div>
	);
};

export default PhoneNumberLinkInput;
