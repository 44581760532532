import React, { ReactElement } from "react";
import "./scss/DetailsItem.scss";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";

interface IProps {
	icon?: ReactElement;
	title: string;
	value: number | string;
	content?: string;
	suffix?: string;
	noBackground?: boolean;
}

const DetailsItem: React.FC<IProps> = ({
	icon,
	title,
	value,
	content,
	suffix,
	noBackground = false,
}) => {
	return (
		<div
			className="column details-item"
			style={{
				background: noBackground ? "transparent" : "var(--light-grey)",
				padding: noBackground ? "0px 13px 0px" : "10px 13px 15px",
			}}
		>
			<div className="flex">
				{icon && <div className="icon">{icon}</div>}
				<span className="title">{title}</span>

				<span className="value">
					{
						typeof value === "number"
						?
						numberWithSpaces(value)
						:
						value
					} {suffix}
				</span>
			</div>
			<div className="flex content">
				<span
					style={{
						color: noBackground ? "var(--grey)" : "var(--fridaa-blue)",
						width: noBackground ? "70%" : "100%",
					}}
				>
					{content}
				</span>
			</div>
		</div>
	);
};

export default DetailsItem;
