import React, { useState } from "react";
import "./scss/DownloadAppTab.scss";

interface IProps {
  items: Array<string>;
  CBHandleClick: (name: string) => void;
}

const DetailsTab: React.FC<IProps> = ({ items, CBHandleClick }) => {
  const [activeTab, setActiveTab] = useState<string>(items[0]);
  console.log("items", items);
  return (
    <div className="flex details-tab">
      <div className="container">
        <button
          onClick={() => {
            CBHandleClick("Email");
            setActiveTab("Email");
          }}
          className={activeTab === "Email" ? "mensual" : "grey"}
        >
          <span>{items[0]}</span>
        </button>
        <button
          onClick={() => {
            CBHandleClick("Téléphone");
            setActiveTab("Téléphone");
          }}
          className={activeTab === "Téléphone" ? "mensual" : "grey"}
        >
          <span>{items[1]}</span>
        </button>
      </div>
    </div>
  );
};

export default DetailsTab;
