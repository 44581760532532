import React, { useEffect, useState } from "react";
import "../scss/OnboardingPropertyDetails.scss";

/** ASSETS */
// import { ReactComponent as ContributionIcon } from "assets/svg/contribution-icon.svg";

/** COMPONENTS */
import OnboardingInputPropertyType from "components/common/OnboardingInputPropertyType/OnboardingInputPropertyType.component";
// import OnboardingAddCoBorrower from "./OnboardingAddCoBorrower.component";
import OnboardingNextButton from "components/common/OnboardingFundingNextButton/OnboardingFundingNextButton.component";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** STORE */
import {
  selectStoreOnboardingType,
  // selectStoreOnboardingPersonalContribution,
  updateStoreOnboarding,
  selectStoreOnboardingConstructPrice,
  selectStoreOnboardingLandPrice,
  selectStoreOnboardingPrice,
  selectStoreOnboardingWorksPrice,
} from "features/onboarding/onboardingFundingSlice";

/** INTERFACES */
// import { IBankRates } from "interfaces/IBankRates.interface";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";
import { numberWithoutSpaces } from "utils/numberWithoutSpaces";

const OnboardingPropertyDetails: React.FC = () => {

  const [isEmpty, setIsEmpty] = useState(false);
  const [isEmpty1, setIsEmpty1] = useState(false);
  const [isEmpty2, setIsEmpty2] = useState(false);

  const [isEmpty3, setIsEmpty3] = useState(false);

	const [isClicked, setIsClicked] = useState(false);

	const [isClicked1, setIsClicked1] = useState(false);

	const [isClicked2, setIsClicked2] = useState(false);

	const [isClicked3, setIsClicked3] = useState(false);

  const type: number = useAppSelector(selectStoreOnboardingType);
  // const personalContribution: number | null = useAppSelector(
  //   selectStoreOnboardingPersonalContribution
  // );
  const price: number | null = useAppSelector(selectStoreOnboardingPrice);
  const worksPrice: number | null = useAppSelector(
    selectStoreOnboardingWorksPrice
  );
  const landPrice: number | null = useAppSelector(
    selectStoreOnboardingLandPrice
  );
  const constructPrice: number | null = useAppSelector(
    selectStoreOnboardingConstructPrice
  );
  const dispatch = useAppDispatch();
  // const [rates, setRates] = useState<Array<IBankRates>>([]);

  const handleChange0 = (newPrice: any) => {
    dispatch(
      updateStoreOnboarding({
        price: numberWithoutSpaces(newPrice.target.value),
      })
    );
  };
  const handleChange1Bis = (newWorksPrice: any) => {
    dispatch(
      updateStoreOnboarding({
        worksPrice: numberWithoutSpaces(newWorksPrice.target.value),
      })
    );
  };
  const handleChange2 = (newLandPrice: any) => {
    // console.log("newLandPrice.target.value", newLandPrice.target.value);
    dispatch(
      updateStoreOnboarding({
        landPrice: numberWithoutSpaces(newLandPrice.target.value),
      })
    );
  };
  const handleChange2Bis = (newConstructPrice: any) => {
    // console.log(
    //   "newConstructPrice.target.value",
    //   newConstructPrice.target.value
    // );
    dispatch(
      updateStoreOnboarding({
        constructPrice: numberWithoutSpaces(newConstructPrice.target.value),
      })
    );
  };

  useEffect(() => {
    if(type === 0 || type === 3) {
		  if (price === null) {
		  	setIsEmpty(true);
		  } else {
		  	setIsEmpty(false);
		  }
    }
	}, [price, type]);

  useEffect(() => {
    setIsClicked(false);
    setIsClicked1(false);
    if(type === 1) {
		  if (price === null) {
		  	setIsEmpty(true);
		  	setIsEmpty1(false);
		  } else if(worksPrice === null) {
        setIsEmpty1(true);
        setIsEmpty(false);
      } else {
        setIsEmpty(false);
		  	setIsEmpty1(false);
		  }
    }
	}, [price, worksPrice, type]);

  useEffect(() => {
    setIsClicked2(false);
    setIsClicked3(false);
    if(type === 2) {
		  if (landPrice === null) {
		  	setIsEmpty2(true);
        setIsEmpty3(false);
		  } else if(constructPrice === null) {
		  	setIsEmpty2(false);
        setIsEmpty3(true);
      } else {
		  	setIsEmpty2(false);
        setIsEmpty3(false);
		  }
    }
	}, [landPrice, constructPrice, type]);

  return (
    <div className="column content-center onboarding-contribution">
      <h1>Quel est le montant de votre projet ?</h1>

      <OnboardingInputPropertyType
        type={type}
        label={""}
        isClicked={isClicked}
        isClicked1={isClicked1}
        isClicked2={isClicked2}
        isClicked3={isClicked3}
        inputNameConstructPrice="constructPrice"
        inputNameNewPrice="price"
        inputNameNewWorksPrice="worksPrice"
        inputNameNewLandPrice="landPrice"
        valueConstructPrice={
          constructPrice === null ? "" : numberWithSpaces(constructPrice)
        }
        valueNewPrice={price === null ? "" : numberWithSpaces(price)}
        valueNewWorksPrice={
          worksPrice === null ? "" : numberWithSpaces(worksPrice)
        }
        valueNewLandPrice={
          landPrice === null ? "" : numberWithSpaces(landPrice)
        }
        // icon={<ContributionIcon />}
        units={["€"]}
        CBHandleChange0={handleChange0}
        CBHandleChange1={handleChange0}
        CBHandleChange1Bis={handleChange1Bis}
        CBHandleChange2={handleChange2}
        CBHandleChange2Bis={handleChange2Bis}
        CBHandleChange3={handleChange0}
      />

      <OnboardingNextButton isEmpty={isEmpty} isEmpty1={isEmpty1} isEmpty2={isEmpty2} isEmpty3={isEmpty3} setIsClicked={setIsClicked} setIsClicked1={setIsClicked1} setIsClicked2={setIsClicked2} setIsClicked3={setIsClicked3}/>
    </div>
  );
};

export default OnboardingPropertyDetails;
