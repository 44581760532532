import React from "react";
import "./scss/OnboardingNavigator.scss";

/** COMPONENTS */
import OnboardingHeader from "./components/OnboardingFundingHeader.component";
import OnboardingHelpModal from "./components/OnboardingHelpModal.component";

/** CONSTANTS */
import { COnboardingFundingNavigator } from "./constants/COnboardingFundingNavigator.constant";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** STORE */
import {
	selectStoreOnboardingDisplayHelp,
	selectStoreOnboardingStep,
	updateStoreOnboarding,
} from "features/onboarding/onboardingFundingSlice";

const OnboardingFundingNavigator: React.FC = () => {
	const dispatch = useAppDispatch();
	const onboardingStep: number = useAppSelector(selectStoreOnboardingStep);
	const displayHelp: boolean = useAppSelector(selectStoreOnboardingDisplayHelp);

	return (
		<div className="column onboarding-navigator">
			<OnboardingHeader />

			{COnboardingFundingNavigator[onboardingStep]}

				<OnboardingHelpModal
					open={displayHelp}
					step={onboardingStep}
					CBHandleClose={() =>
						dispatch(updateStoreOnboarding({ displayHelp: false }))
					}
				/>
		</div>
	);
};

export default OnboardingFundingNavigator;