import React from "react";
import "../scss/SideBarIllu.scss";

/** SVGS */
import { ReactComponent as HouseNoHandHomepage } from "assets/svg/house-nohand-homepage.svg";

const SideBarIllu: React.FC = () => {
  return (
    <div className="sidebar-illu">
      <div className="ellipse"></div>
      <div className="illu">
        <HouseNoHandHomepage />
      </div>
    </div>
  );
};

export default SideBarIllu;
