/** INTERFACES */
import { IOnboarding } from "interfaces/IOnboarding.interface";

class onboardingService {
  public saveOnboardingOnLocalStorage(data: IOnboarding) {
    window.localStorage.setItem("onboarding", JSON.stringify(data));
  }


  
}

export default new onboardingService();