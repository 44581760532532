import React from "react";
import "../scss/SideBarCapacity.scss";

/** COMPONENTS */
import FinancialInfos from "components/common/FinancialInfos/FinancialInfos.component";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import { logEvent } from "firebase/analytics";
import analytics from "firebase-config";

/** LODASH */
import { get } from "lodash";

/** SVGS */
// import { ReactComponent as FridaaLogo } from "assets/svg/fridaa-logo-name.svg";

/** STORE */
import { useAppDispatch, useAppSelector } from "hooks";
import { selectStoreCapacity, updateStoreCapacity } from "features/capacity/capacitySlice";
import logo from "assets/png/logo-fridaaXpartner-ok.png";

const SideBarCapacity: React.FC = () => {
	const dispatch = useAppDispatch();
	const capacity: any = useAppSelector(selectStoreCapacity);
	
	const CBHandleChange = (event: any) => {
		dispatch(
			updateStoreCapacity({
				[event.target.name]: event.target.value,
			})
		);
	};

	const handleSubmit = (eventName: string): void => {
		const event: string | undefined = get(EFirebaseEvents, `CAPACITY_${eventName}_UPDATE`);

		if (event !== undefined) {
			logEvent(analytics, event)
		}
	}

	return (
		<div className="column space-between sidebar-capacity">
			<div className="logo">
				<a href="/">
          			<img src={logo} className="logo" alt="fridaa-homepage-logo" />
				</a>
			</div>

			<FinancialInfos
				monthlyIncomes={capacity.monthlyIncomes}
				mainBorrowerIncomes={capacity.mainBorrowerIncomes}
				coBorrowerIncomes={capacity.coBorrowerIncomes}
				monthlyCredits={capacity.monthlyCredits}
				personalContribution={capacity.personalContribution}
				CBHandleChange={CBHandleChange}
				CBHandleSubmit={handleSubmit}
			/>
		</div>
	);
};

export default SideBarCapacity;
