import React from "react";
import "./scss/InputCard.scss";

/** ASSETS */
import { toast } from "react-toastify";

interface IProps {
	autoFocus?: boolean;
	label: string;
	inputName: string;
	icon: React.ReactElement;
	units: Array<string>;
	value: string;
	keyboardType?: string;
	CBHandleChange: (event: any) => void;
	CBHandleClose?: () => void;
}

const InputCard: React.FC<IProps> = ({
	autoFocus = false,
	label,
	inputName,
	icon,
	units,
	value,
	keyboardType,
	CBHandleChange,
	CBHandleClose,
}) => {

	const confirmValue = () => {
		if (inputName === 'personalContribution' && value === '0') {
			// console.log('confirmValue', value)
			toast("😕  Attention, sans apport votre financement peut être difficile. ", {
				position: toast.POSITION.BOTTOM_RIGHT,
				icon: undefined,
				hideProgressBar: true,
				style: { fontFamily: 'BalooDa2Medium', color: '#120136' }
		  	});
		}

		if (CBHandleClose !== undefined ) {
			CBHandleClose();
		}
	}

	return (
		<>
		<div className="column input-card">
			<div className="flex space-between header">
				<div
					className="label"
					style={{
						background: label === "" ? "transparent" : "var(--light-grey)",
					}}
				>
					<span>{label}</span>
				</div>
				{CBHandleClose !== undefined && (
					<button onClick={confirmValue} className="modal-input-confirm">
						OK
					</button>
				)}
			</div>

			<div className="icon">{icon}</div>

			<div className="flex items-center content-center value">
				<input
					autoFocus={autoFocus}
					name={inputName}
					value={value}
					onChange={CBHandleChange}
					type={keyboardType ? keyboardType : "text"}
				/>

				{units.length > 0 && (
					<div className="flex units">
						<span>{units[0]}</span>

						{units.length === 2 && <span>{units[1]}</span>}
					</div>
				)}
			</div>
		</div>
		</>
	);
};

export default InputCard;
