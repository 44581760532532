import React from "react";

/** COMPONENTS */
import SideBarFundingCapacity from "../components/SideBarFundingCapacity.component";
import SideBarFundingOnboarding from "../components/SideBarFundingOnboarding.component";
import SideBarOnboarding from "../components/SideBarOnboarding.component";
import SideBarCapacity from "../components/SideBarCapacity.component";

export const CSidebarComponents: any = {
  onboardingFunding: <SideBarFundingOnboarding />,
  capacityFunding: <SideBarFundingCapacity />,
  onboarding: <SideBarOnboarding />,
  capacity: <SideBarCapacity />,
}