import axios from "axios";

class apiService {
	headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
	};

	async get(endpoint: any): Promise<any> {
		return new Promise((resolve, reject) => {
			axios
				.get(`${endpoint}`, {
					headers: {
						...this.headers,
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				})
				.then((res: any) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err);
					reject(err);
				});
		});
	}

	async post(endpoint: any, query?: any) {
		// console.log('query', query);
		return new Promise((resolve, reject) => {
			axios
				.post(
					`${endpoint}`,
					{
						...query,
					},
					{
						headers: {
							...this.headers,
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
					}
				)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err);
					reject(err);
				});
		});
	}

	async put(endpoint: any, query: any) {
		return new Promise((resolve, reject) => {
			axios
				.put(
					`${endpoint}`,
					{
						...query,
					},
					{
						headers: {
							...this.headers,
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
					}
				)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err);
					reject(err);
				});
		});
	}

	// { data: { foo: "bar" }, headers: { "Authorization": "***" } });

	async delete(endpoint: any, query: any) {
		try {
			const res = await axios.delete(`${endpoint}`, {
				data: { ...query },
				headers: {
					...this.headers,
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
			});
			return res;
		} catch (error: any) {
			return error.response;
		}
	}

	public uploadFile = async (endpoint: any, formData?: any) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${endpoint}`, formData, {
					headers: this.headers,
				})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
}

export default new apiService();
