import React from "react";
import "./scss/DetailsTab.scss";

interface IProps {
	items: Array<string>;
	selected?: number;
	CBHandleClick: (name: string) => void;
}

const DetailsTab: React.FC<IProps> = ({ items, selected, CBHandleClick }) => {
	return (
		<div className="flex content-center details-tab">
			<div className="container">
				{items.map((itemName: string, index: number) => {
					return (
						<button
							onClick={() => CBHandleClick(itemName)}
							key={index}
							className={selected === index ? "current" : "not-current"}
						>
							<span>{itemName}</span>
						</button>
					);
				})}
			</div>
		</div>
	);
};

export default DetailsTab;
