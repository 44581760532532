import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

/** SLICE */
import capacityFundingReducer from "features/capacity/capacityFundingSlice";
import onboardingFundingReducer from "features/onboarding/onboardingFundingSlice";
import capacityReducer from "features/capacity/capacitySlice";
import onboardingReducer from "features/onboarding/onboardingSlice";

export const store = configureStore({
  reducer: {
    capacity: capacityReducer,
    onboarding: onboardingReducer,
    capacityFunding: capacityFundingReducer,
    onboardingFunding: onboardingFundingReducer,
  },
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
