import React from "react";
import "./scss/DiscoverFundingPasseportButton.scss";

interface IProps {
  callback: () => void;
  title: string;
  disabled?: boolean;
}

const DiscoverFundingPasseportButton: React.FC<IProps> = ({callback, title, disabled}) => {

  return (
    <button disabled={disabled} className="flex content-center items-center  discover-funding-passeport-button" onClick={() => callback()}>
      <span>{title}</span>
    </button>
  )
}

export default DiscoverFundingPasseportButton;