import React, { useEffect, useState } from "react";

/** COMPONENTS */
import SideBarIllu from "./components/SideBarIllu.component";

/** CONSTANTS */
import { CSidebarComponents } from "./constants/CSidebarComponents.constant";

const Sidebar: React.FC = () => {
	const [componentNameToRender, setComponentNameToRender] =
		useState<string>("");

	useEffect(() => {
		const pathnames: Array<string> = window.location.pathname.split("/");

		if (pathnames.length > 0) {
			setComponentNameToRender(pathnames[1]);
		}
	}, []);

	return (
		<div className="sidebar">
			{CSidebarComponents[componentNameToRender] ?? <></>}
			<SideBarIllu />
		</div>
	);
};

export default Sidebar;
