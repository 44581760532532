import React, { useState, useEffect } from "react";
import "../scss/LoanDurationSelectorItem.scss";

interface IProps {
	selected: number | null;
	value: number;
	CBHandleClick: (duration: number) => void;
}

const LoanDurationSelectorItem: React.FC<IProps> = ({
	selected,
	value,
	CBHandleClick,
}) => {
	const [isSelected, setIsSelected] = useState<boolean>(false);

	useEffect(() => {
		if (selected === value) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
	}, [selected, value]);

	return (
		<div className="loan-duration-selector-item">
			<button
				className={`column ${isSelected ? "selected" : "not-selected"}`}
				onClick={() => CBHandleClick(value)}
			>
				<span className="value">{value}</span>
				{isSelected && <span className="year">ans</span>}
			</button>
		</div>
	);
};

export default LoanDurationSelectorItem;
