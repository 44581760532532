import React from "react";

/** REACT ROUTER DOM */
import { useLocation } from "react-router-dom";

const useQuery = (key: string): string | null => {
	const { search } = useLocation();

	return React.useMemo(
		() => new URLSearchParams(search).get(key),
		[search, key]
	);
};

export default useQuery;
