import React, { useEffect, useState } from "react";
import "./scss/DownloadAppModal.scss";

/** ASSETS */
import { ReactComponent as AppStoreIcon } from "assets/svg/appstore-download.svg";
import { ReactComponent as PlayStoreIcon } from "assets/svg/playstore-download.svg";
import { ReactComponent as FridaaLogoIcon } from "assets/svg/fridaa-homepage-logo.svg";
import { ReactComponent as CloseIcon } from "assets/svg/x.svg";

/** COMPONENTS */
import DownloadAppCarousel from "./components/DownloadAppCarousel.component";
import DownloadAppModalDesktop from "./components/DownloadAppModalDesktop.component";

/** MATERIAL UI */
import { Dialog, DialogContent } from "@material-ui/core";

/** UTILS */
import { detectDeviceSysteme } from "utils/detectDeviceSysteme.util";
import { openInNewTab } from "utils/openInNewTab.util";
import { isDesktopWidth } from "utils/isDesktopWidth.util";

interface IProps {
	CBHandleClose: () => void;
}

const DownloadAppModal: React.FC<IProps> = ({ CBHandleClose }) => {
	const [isDesktop, setIsDesktop] = useState<boolean>(false);
	const [deviceSysteme, setDeviceSysteme] = useState<string | null>(null);

	useEffect(() => {
		setDeviceSysteme(detectDeviceSysteme);
		setIsDesktop(isDesktopWidth);
	}, []);

	const handleClick = (): void => {
		openInNewTab("https://fridaa.onelink.me/qP6S/73874343");
	};

	if (isDesktop) {
		return (
			<Dialog
				className="download-app-modal"
				open={true}
				keepMounted
				onClose={CBHandleClose}
			>
				<DialogContent className="column">
					<DownloadAppModalDesktop CBHandleClose={CBHandleClose} />
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Dialog
			className="download-app-modal"
			open={true}
			keepMounted
			onClose={CBHandleClose}
		>
			<DialogContent className="column">
				<>
					<button className="close" onClick={CBHandleClose}>
						<CloseIcon />
					</button>
					<div className="header">
						<h2>Découvrez bien plus en téléchargeant l’app</h2>
						<div className="flex icon">
							<FridaaLogoIcon />
						</div>
					</div>

					{deviceSysteme === "ios" ? (
						<button className="store-logo" onClick={handleClick}>
							<AppStoreIcon />
						</button>
					) : (
						<button className="store-logo" onClick={handleClick}>
							<PlayStoreIcon />
						</button>
					)}

					<DownloadAppCarousel />
				</>
			</DialogContent>
		</Dialog>
	);
};

export default DownloadAppModal;
