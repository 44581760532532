import React from "react";
import "../scss/OnboardingFundingHeader.scss";

/** ASSETS */
import { ReactComponent as Chevron } from "assets/svg/chevron-left-white.svg";
import { ReactComponent as Warning } from "assets/svg/warning-white.svg";

/** COMPONENTS */
import OnboardingHeaderStepIndicator from "./OnboardingHeaderStepIndicator.component";

/** ENUMS */
import { EFirebaseEvents } from "enums/EFirebaseEvents.enum";

/** FIREBASE */
import analytics from "firebase-config";
import { logEvent } from "@firebase/analytics";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** REACT ROUTER */
import { useHistory } from "react-router";

/** STORE */
import {
  onboardingSubSteps,
  selectStoreOnboardingHasCredits,
  selectStoreOnboardingIsError,
  selectStoreOnboardingNumberOfSteps,
  selectStoreOnboardingStep,
  updateStoreOnboarding,
} from "features/onboarding/onboardingFundingSlice";

const OnboardingHeader: React.FC = () => {
  const step: number = useAppSelector(selectStoreOnboardingStep);
  const numberOfSteps: number = useAppSelector(
    selectStoreOnboardingNumberOfSteps
  );
  const hasCredits: boolean | null = useAppSelector(
    selectStoreOnboardingHasCredits
  );
  const isError: boolean | null = useAppSelector(selectStoreOnboardingIsError);
  const dispatch = useAppDispatch();
  let history = useHistory();

  const handleLeave = (): void => {
    logEvent(analytics, EFirebaseEvents.ONBOARDING_LEAVE_PRESSED);
    history.push("/");
  };

  const handleGoBack = (): void => {
    if (step === 0) {
      handleLeave();
      return;
    }

    if (step === 2 && hasCredits === false) {
      dispatch(updateStoreOnboarding({ hasCredits: null }));
    }
    dispatch(onboardingSubSteps(1));
  };

  return (
    <div className="flex space-between onboarding-funding-header">
      <button className="go-back" onClick={handleGoBack}>
        <Chevron />
      </button>

      <OnboardingHeaderStepIndicator
        step={step}
        numberOfSteps={numberOfSteps}
      />
      {isError && (
        <>
          <div className="mendatory-warning flex items-center ">
            <Warning />
            <span>champs obligatoires</span>
          </div>
          <div></div>
        </>
      )}
      {!isError && <div></div>}
    </div>
  );
};

export default OnboardingHeader;
