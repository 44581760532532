const floatNumberWithSpaces = (number: number | string): string => {
	let parts = number.toString().split(".");

	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	return parts.join(".");
};

export const numberWithSpaces = (number: number | string): string => {
  number = number.toString().replace(/\s/g,'');

	if (number.toString().indexOf(".") !== -1) {
		return floatNumberWithSpaces(number);
	}
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
};
