import React from "react";

/** ASSETS */
import {ReactComponent as PictoCredit} from "assets/svg/ic-picto-credit.svg"
import {ReactComponent as PictoRevenus} from "assets/svg/ic-picto-revenus.svg"
import {ReactComponent as PictoApport} from "assets/svg/ic-picto-apport.svg"

export const CInputValueBlockIcons: any = {
  "picto-revenus": <PictoRevenus />,
  "picto-credit": <PictoCredit />,
  "picto-apport": <PictoApport />,
}