import React from "react";

/** COMPONENTS */
import OnboardingCredits from "../components/OnboardingCredits.component";
import OnboardingIncomes from "../components/OnboardingIncomes.component";
import OnboardingContribution from "../components/OnboardingContribution.component";

export const COnboardingNavigator: Array<React.ReactElement> = [
	<OnboardingIncomes />,
	<OnboardingCredits />,
	<OnboardingContribution />
];
