import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

/** INTERFACES */
import { IOnboardingFunding } from "interfaces/IOnboardingFunding.interface";
import { isNaN } from "lodash";

let apimoPrice = parseFloat(localStorage.getItem(`${process.env.REACT_APP_PARTNER_PREFIX}price`) ?? '0');
if (isNaN(apimoPrice)) apimoPrice = 0;

const initialState: IOnboardingFunding = {
  step: 0,
  numberOfSteps: 6,
  incomes: null,
  borrowerIncomes: null,
  isCoBorrower: false,
  hasCredits: null,
  monthlyCredits: null,
  personalContribution: null,
  displayHelp: false,
  estateType: 0,
  constructPrice: null,
  landPrice: null,
  price: apimoPrice,
  worksPrice: null,
  age: null,
  ageCoborrower: false,
  ageCoBorrowerValue: null,
  isError: false,
};

export const onboardingFundingSlice = createSlice({
  name: "onboardingFunding",
  initialState,
  reducers: {
    updateStoreOnboarding: (state: any, action: PayloadAction<any | any>) => {
      return { ...state, ...action.payload };
    },
    onboardingAddSteps: (state: any, action: PayloadAction<number>) => {
      
      return { ...state, step: state.step + action.payload };
    },
    onboardingSubSteps: (state: any, action: PayloadAction<number>) => {
      return { ...state, step: state.step - action.payload };
    },
  },
});

export const { updateStoreOnboarding, onboardingAddSteps, onboardingSubSteps } =
  onboardingFundingSlice.actions;
export const selectStoreOnboarding = (state: RootState) =>
  state.onboardingFunding;
export const selectStoreOnboardingStep = (state: RootState): number =>
  state.onboardingFunding.step;
export const selectStoreOnboardingNumberOfSteps = (state: RootState): number =>
  state.onboardingFunding.numberOfSteps;
export const selectStoreOnboardingIncomes = (state: RootState): number | null =>
  state.onboardingFunding.incomes;
export const selectStoreOnboardingBorrowerIncomes = (
  state: RootState
): number | null => state.onboardingFunding.borrowerIncomes;
export const selectStoreOnboardingIsCoBorrower = (state: RootState): boolean =>
  state.onboardingFunding.isCoBorrower;
export const selectStoreOnboardingHasCredits = (
  state: RootState
): boolean | null => state.onboardingFunding.hasCredits;
export const selectStoreOnboardingMonthlyCredits = (
  state: RootState
): number | null => state.onboardingFunding.monthlyCredits;
export const selectStoreOnboardingPersonalContribution = (
  state: RootState
): number | null => state.onboardingFunding.personalContribution;
export const selectStoreOnboardingDisplayHelp = (state: RootState): boolean =>
  state.onboardingFunding.displayHelp;
export const selectStoreOnboardingType = (state: RootState): number =>
  state.onboardingFunding.estateType;
export const selectStoreOnboardingConstructPrice = (
  state: RootState
): number | null => state.onboardingFunding.constructPrice;
export const selectStoreOnboardingLandPrice = (
  state: RootState
): number | null => state.onboardingFunding.landPrice;
export const selectStoreOnboardingPrice = (state: RootState): number | null =>
  state.onboardingFunding.price;
export const selectStoreOnboardingWorksPrice = (
  state: RootState
): number | null => state.onboardingFunding.worksPrice;
export const selectStoreOnboardingAge = (state: RootState): number | null =>
  state.onboardingFunding.age;
export const selectStoreOnboardingAgeCoborrower = (state: RootState): boolean =>
  state.onboardingFunding.ageCoborrower;
export const selectStoreOnboardingAgeCoborrowerValue = (
  state: RootState
): number | null => state.onboardingFunding.ageCoBorrowerValue;
export const selectStoreOnboardingIsError = (state: RootState): boolean =>
  state.onboardingFunding.isError;
export default onboardingFundingSlice.reducer;
