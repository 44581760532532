import React, { useState } from "react";
import axios from "axios";
import "../scss/DownloadAppModalDesktop.scss";

/** ASSETS */
import { ReactComponent as FridaaLogoIcon } from "assets/svg/fridaa-homepage-logo.svg";
import { IoClose } from "react-icons/io5";

/** COMPONENTS */
import PhoneNumberLinkInput from "components/common/PhoneNumberLinkInput/PhoneNumberLinkInput.component";
import ScanQRCode from "components/common/ScanQRCode/ScanQRCode.component";
import DownloadAppCarousel from "./DownloadAppCarousel.component";
import { toast } from "react-toastify";

interface IProps {
	CBHandleClose: () => void;
}

const DownloadAppModalDesktop: React.FC<IProps> = ({ CBHandleClose }) => {

	const [smsError, setSmsError] = useState<string | null>(null);

	const sendSms = async (phone: string) => {
		try {
			setSmsError(null);
			const instance = axios.create({
				baseURL: 'https://us-central1-fridaaapp.cloudfunctions.net/',
				timeout: 10000,
				headers: {'Access-Control-Allow-Origin': '*'}
			});

			const response = await instance.post('/sendLinkSMS', { phone: phone ? phone.replaceAll(' ', '') : '' });

			if (response && response.status === 200) {
				CBHandleClose();
				toast.success("SMS envoyé ! Vous allez recevoir le lien.", {
					position: toast.POSITION.BOTTOM_RIGHT,
					icon: undefined,
					hideProgressBar: true,
					style: { fontFamily: 'BalooDa2Medium', color: '#120136' }
				});
			}
			else if (response && response.status === 400) {
				setSmsError("Veuillez saisir un numéro de téléphone valide.");
			}
			else if (!response || response.status === 500) {
				setSmsError("Une erreur est survenue. Veuillez réessayer plus tard.");
			}
		} catch (e: any) {
			if (e.response && e.response.status === 400) {
				setSmsError("Veuillez saisir un numéro de téléphone valide.");
			}
			else {
				setSmsError("Une erreur est survenue. Veuillez réessayer plus tard.");
			}
		}
	}

	return (
		<div className="flex download-app-modal-desktop">
			<button className="close" onClick={CBHandleClose}>
				<IoClose />
			</button>
			<div className="left-column">
				<h2>Découvrez bien plus en téléchargeant l’app</h2>
				<div className="logo">
					<FridaaLogoIcon />
				</div>
				<div className="link-sentence">
					<span>vous recevrez un lien</span>
				</div>
				<PhoneNumberLinkInput onSubmit={(phone: string) => sendSms(phone)} error={smsError} />
				<ScanQRCode qrCodeName="download" />
			</div>
			<div className="column content-center right-column">
        <DownloadAppCarousel />
      </div>
		</div>
	);
};

export default DownloadAppModalDesktop;
