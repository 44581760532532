import React, { useState, useEffect } from "react";
import "./scss/InputFundingValueBlock.scss";

/** ASSETS */
import { ReactComponent as Edit } from "assets/svg/edit.svg";

/** CONSTANTS */
import { CInputValueBlockIcons } from "./constants/CInputValueBlockIcons.constant";
import { CInputValueBlockModalContent } from "./constants/CInputValueBlockModalContent.constant";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";
import { numberWithoutSpaces } from "utils/numberWithoutSpaces";
import ModalInputValueCard from "../ModalInputValueCard/ModalInputValueCard.component";
import InputCard from "../InputCard/InputCard.component";
import { get } from "lodash";

interface IProps {
  iconName: string;
  name: string;
  modalNames: Array<string>;
  label: string;
  value: number | null;
  modalValues: Array<number>;
  CBHandleChange: (event: any) => void;
  CBHandleSubmit?: (eventName: string) => void;
}

const InputValueBlock: React.FC<IProps> = ({
  iconName,
  name,
  modalNames,
  label,
  value,
  modalValues,
  CBHandleChange,
  CBHandleSubmit,
}) => {
  const [currentValue, setCurrentValue] = useState<number>(0);
//   console.log("InputValueBlock.currentValue", currentValue);
  // const [isFocused, setIsFocused] = useState<boolean>(false);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<Array<any>>([]);
  // const inputRef: any = createRef();

  useEffect(() => {
    if (value === null) {
      setCurrentValue(0);
    } else {
      setCurrentValue(value);
    }
  }, [value]);

  useEffect(() => {
    const data: Array<any> = [];

    modalNames.forEach((modalName: string) => {
      const object: any = get(CInputValueBlockModalContent, modalName);

      if (object !== undefined) {
        data.push(object);
      }
    });

    setModalData(data);
  }, [modalNames]);

  const handleChange = (event: any): void => {
    CBHandleChange({
      target: {
        name: event.target.name,
        value: numberWithoutSpaces(event.target.value),
      },
    });
  };

  return (
    <>
      <button
        className="input-value-block"
        onClick={() => setDisplayModal(true)}
      >
        <div className="icons flex space-between">
          {CInputValueBlockIcons[iconName] && (
            <div className="icon">{CInputValueBlockIcons[iconName]}</div>
          )}

          <div className="edit">
            <Edit />
          </div>
        </div>

        <div className="column">
          <span className="label">{label}</span>

          <div className="flex items-center value">
            <span>{numberWithSpaces(currentValue)}</span>
            <span className="currency">€</span>
          </div>
        </div>
      </button>

      {displayModal && (
        <ModalInputValueCard
          CBHandleClose={() => {
            setDisplayModal(false);
          }}
        >
          {modalData.map((data: any, index: number) => {
            return (
              <InputCard
                autoFocus={index === 0}
                label={data.label}
                inputName={data.inputName}
                icon={data.icon}
                units={data.units}
                value={numberWithSpaces(modalValues[index] ?? "0")}
                CBHandleChange={handleChange}
                CBHandleClose={() => {
                  CBHandleSubmit && CBHandleSubmit(data.eventName);
                  setDisplayModal(false);
                }}
                key={index}
              />
            );
          })}
        </ModalInputValueCard>
      )}
    </>
  );
};

export default InputValueBlock;
