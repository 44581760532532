import React from "react";
import "../scss/OnboardingAddCoBorrower.scss";

/** ASSETS */
import { ReactComponent as Add } from "assets/svg/add-icon.svg";

/** HOOKS */
import { useAppDispatch } from "hooks";
import { updateStoreOnboarding } from "features/onboarding/onboardingFundingSlice";

const OnboardingAddCoBorrower: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    dispatch(updateStoreOnboarding({isCoBorrower: true}))
  }

	return (
		<div className="column content-center onboarding-add-coborrower">
			<button className="flex" onClick={handleClick}>
				<Add />
			</button>

			<div className="column tips">
				<span>Ajouter un.e</span>
				<span>co-emprunteur</span>
			</div>
		</div>
	);
};

export default OnboardingAddCoBorrower;
