import React from "react";
import "../scss/OnboardingCredits.scss";

/** ASSETS */
import { ReactComponent as CreditIcon } from "assets/svg/credit-icon.svg";

/** COMPONENTS */
import OnboardingInputCard from "components/common/OnboardingInputCard/OnboardingInputCard.component";
import OnboardingNextButton from "components/common/OnboardingNextButton/OnboardingNextButton.component";
import OnboardingInfosButton from "components/common/OnboardingInfosButton/OnboardingInfosButton.component";
import OnboardingThumb from "components/common/OnboardingThumb/OnboardingThumb.component";

/** HOOKS */
import { useAppDispatch, useAppSelector } from "hooks";

/** REACT ICONS */
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";

/** STORE */
import {
	onboardingAddSteps,
	updateStoreOnboarding,
	selectStoreOnboardingHasCredits,
	selectStoreOnboardingMonthlyCredits,
} from "features/onboarding/onboardingSlice";

/** UTILS */
import { numberWithSpaces } from "utils/numberWithSpaces";
import { numberWithoutSpaces } from "utils/numberWithoutSpaces";

const OnboardingCredits: React.FC = () => {
	// const [infosVisible, setInfosVisible] = useState<boolean>(false);
	const hasCredits: boolean | null = useAppSelector(
		selectStoreOnboardingHasCredits
	);
	const monthlyCredits: number | null = useAppSelector(
		selectStoreOnboardingMonthlyCredits
	);
	const dispatch = useAppDispatch();

	const handleChange = (event: any): void => {
		dispatch(
			updateStoreOnboarding({
				[event.target.name]: numberWithoutSpaces(event.target.value),
			})
		);
	};

	const handleThumbClick = (value: boolean): void => {
		dispatch(updateStoreOnboarding({ hasCredits: value }));

		if (value === false) {
			dispatch(onboardingAddSteps(1));
		}
	};

	const displayHasCreditsSelection = (): any => {
		return (
			<>
				{/* <div className="credits-selection"> */}
				<div className="selection-tips">
					<span>
						Indiquez-le uniquement si vous les conservez après votre projet.
					</span>
				</div>

				<div className="flex content-center">
					<OnboardingThumb
						icon={<FaThumbsDown />}
						color="red-thumb"
						CBHandleClick={() => {
							handleThumbClick(false);
						}}
					/>

					<OnboardingThumb
						icon={<FaThumbsUp />}
						color="green-thumb"
						CBHandleClick={() => {
							handleThumbClick(true);
						}}
					/>
				</div>
				{/* </div> */}
			</>
		);
	};

	return (
		<div
			className={`column content-center onboarding-credits ${
				hasCredits === null ? "select-credits" : ""
			}`}
		>
			<h1>Remboursez-vous des crédits ?</h1>

			{hasCredits === null && <>{displayHasCreditsSelection()}</>}

			{hasCredits === true && (
				<>
					<div className="flex content-center thumbs">
						<OnboardingThumb
							icon={<FaThumbsDown />}
							color="red-thumb"
							notSelected={false}
							CBHandleClick={() => {
								handleThumbClick(false);
							}}
						/>

						<OnboardingThumb
							icon={<FaThumbsUp />}
							color="green-thumb"
							CBHandleClick={() => {
								handleThumbClick(true);
							}}
						/>
					</div>

					<OnboardingInputCard
						label=""
						inputName="monthlyCredits"
						icon={<CreditIcon />}
						units={["€", "/mois"]}
						value={
							monthlyCredits === null ? "" : numberWithSpaces(monthlyCredits)
						}
						CBHandleChange={handleChange}
					/>

					<OnboardingNextButton />
					<OnboardingInfosButton />
				</>
			)}
		</div>
	);
};

export default OnboardingCredits;
